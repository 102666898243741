<template>
  <div>
    <template v-if="isForTable">
      <VDropdown
        :popper-class="['custom-popover-class', `theme-${globalStore.selectedThemeId}`, `app-${globalStore.appName}`]"
        @click.stop
      >
        <!--        :triggers="['hover']"-->
        <!--In tables show only 1 LED for each phase, blue if the last page of the phase is done.-->
        <!-- This will be the popover target (for the events and position) -->
        <div
          v-tooltip="'Click for details'"
          :class="{ 'is-for-table': isForTable }"
          class="user-journey-status-indicator clickable"
        >
          <div
            v-for="(phase, phaseKey) in userJourneyPagesFiltered"
            :key="phaseKey"
            class="phase-wrapper"
          >
            <!--            <template v-if="page !== 4080">-->
            <!--              &lt;!&ndash;              DEBUG&ndash;&gt;-->
            <!--              {{ page }} - -->
            <!--              {{ Object.values(phase).slice(-1)[0] }}-->
            <!--            </template>-->
            <div class="phase">
              <div
                class="led-wrapper"
              >
                <div
                  :ref="setLedToAdjustWidthRefs"
                  :class="{ 'finished-phase': isPastOrCurrentPage(Object.values(phase).slice(-1)[0]) }"
                  class="tooltip-target led"
                >
                  <div
                    v-if="currentPageIsAtPhase(phase)"
                    :style="{ '--percentCompleted': phasePercentCompleted(phase) }"
                    class="percent-completed-over-led"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- This will be the content of the popover -->
        <template #popper>
          <div
            class="user-journey-status-indicator is-for-table"
          >
            <div
              v-for="(phase, phaseKey, phaseIndex) in userJourneyPagesFiltered"
              :key="phaseKey"
              class="phase-wrapper"
            >
              <div class="phase">
                <div
                  v-for="(step, stepKey, stepIndex) in phase"
                  :key="step"
                  v-tooltip="tooltipFor(phaseKey, stepKey.toString())"
                  class="led-wrapper"
                >
                  <div
                    :ref="setLedToAdjustWidthRefs"
                    :class="{ 'current-page': isCurrentPageAndNotLastOfAll(step, stepIndex, phase, phaseIndex), 'finished-phase': isPastOrCurrentPage(step) }"
                    class="led"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </VDropdown>
    </template>
    <template v-else-if="isForAccountDetails">
      <div class="d-flex">
        <div
          class="user-journey-status-indicator is-for-account-details"
        >
          <div
            v-for="(phase, phaseKey, phaseIndex) in userJourneyPagesFiltered"
            :key="phaseKey"
            class="phase-wrapper"
          >
            <div class="phase">
              <div
                v-for="(step, stepKey, stepIndex) in phase"
                :key="step"
                v-tooltip="tooltipFor(phaseKey, stepKey.toString())"
                class="led-wrapper"
              >
                <div
                  :ref="setLedToAdjustWidthRefs"
                  :class="{ 'current-page': isCurrentPageAndNotLastOfAll(step, stepIndex, phase, phaseIndex), 'finished-phase': isPastOrCurrentPage(step) }"
                  class="led"
                />
              </div>
            </div>
          </div>
        </div>

        <template v-if="accountStore.userRoleIsAdministrator && isForAccountDetails">
          <div class="d-flex">
            <AppButtonWithLed
              label="Edit"
              class="ml-gap-1x"
              @click="showModalEditOnboardingStep"
            />
            <AppButtonWithLed
              label="History"
              class="ml-gap-0_38x"
              @click="showModalOnboardingStepHistory"
            />
          </div>
        </template>
      </div>
    </template>
    <template v-else-if="isUserJourneyTalentManual">
      <!---->
      <!-- Show all in one phase -->
      <!---->
      <div
        :class="{ 'is-for-header': isForHeader }"
        class="user-journey-status-indicator"
      >
        <div class="phase-wrapper m-0">
          <div class="phase">
            <template
              v-for="(phase, phaseKey) in userJourneyPagesFiltered"
            >
              <div
                v-for="(step, stepKey) in phase"
                :key="`phase-${phaseKey}-step-${step}`"
                v-tooltip="tooltipFor(phaseKey, stepKey.toString())"
                class="led-wrapper"
              >
                <div
                  :ref="setLedToAdjustWidthRefs"
                  :class="{ 'finished-phase': isPastOrCurrentPage(step) }"
                  class="led"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        :class="{ 'is-for-table': isForTable, 'is-for-header': isForHeader }"
        class="user-journey-status-indicator"
      >
        <div
          v-for="(phase, phaseKey) in userJourneyPagesFiltered"
          :key="phaseKey"
          class="phase-wrapper"
        >
          <div class="phase">
            <div
              v-for="(step, stepKey) in phase"
              :key="step"
              v-tooltip="tooltipFor(phaseKey, stepKey.toString())"
              class="led-wrapper"
            >
              <div
                :ref="setLedToAdjustWidthRefs"
                :class="{ 'finished-phase': isPastOrCurrentPage(step) }"
                class="led"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <!---->
    <!---->
    <!---->
    <template v-if="accountStore.userRoleIsAdministrator && isForAccountDetails">
      <!-- Edit onboarding step modal -->
      <VeeForm
        v-slot="{ handleSubmit }"
        @invalid-submit="onInvalidSubmit"
      >
        <BModal
          v-model="isEditOnboardingStepModalVisible"
          teleport-disabled
          size="sm"
          centered
        >
          <template #header>
            Set GetReady Program step
          </template>

          <template #default>
            <!-- Edit mode -->
            <AppFormGroupWithValidation
              name="step"
              :model-value="selectedStepToSave"
              label="Step"
              rules="required"
            >
              <template #form-input-inside-form-group>
                <BDropdown
                  :text="stepDropdownText"
                  class="filter-dropdown w-100"
                >
                  <div
                    v-for="(phase, phaseKey) in userJourneyPagesFiltered"
                    :key="phaseKey"
                  >
                    <BDropdownItem
                      v-for="(step, stepKey) in phase"
                      :key="step"
                      :active="Boolean(selectedStepToSave && selectedStepToSave.step === step)"
                      @click="selectStepOnDropdown({ phaseKey, stepKey: stepKey.toString(), step })"
                    >
                      {{ step }} - {{ translationStore.t(`${translationPrefix}.${phaseKey}.${stepKey}`) }}
                    </BDropdownItem>
                  </div>
                </BDropdown>
                <div
                  v-if="willCreateANewQuestionnaireAttempt"
                  class="mt-gap-0_38x"
                >
                  The selected step is before or at the questionnaire step; this will create a new questionnaire attempt for the user.
                </div>
              </template>
            </AppFormGroupWithValidation>
          </template>

          <template #footer>
            <AppButtonWithLed
              label="Cancel"
              class="w-25"
              @click="isEditOnboardingStepModalVisible = false"
            />
            <AppButtonWithLed
              :disabled="busy.saving"
              label="Save"
              class="w-25 ml-gap-0_38x"
              @click="handleSubmit($event, saveStep)"
            />
          </template>
        </BModal>
      </VeeForm>
      <!-- GetReady Program user history modal -->
      <BModal
        v-model="isOnboardingStepHistoryModalVisible"
        teleport-disabled
        size="md"
        centered
      >
        <template #header>
          GetReady Program History
        </template>

        <template #default>
          <GetReadyProgramUserHistory
            v-if="entity && 'id' in entity && isOnboardingStepHistoryModalVisible"
            :user-id="entity.id"
            :user-journey-pages-filtered="userJourneyPagesFiltered"
            :translation-prefix="translationPrefix"
          />
        </template>

        <template #footer>
          <AppButtonWithLed
            label="Close"
            class="w-25"
            @click="isOnboardingStepHistoryModalVisible = false"
          />
        </template>
      </BModal>
    </template>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { computed, inject, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue';
import type { PropType } from 'vue';
import { AxiosError } from 'axios';
import type { AxiosStatic } from 'axios';
import debounce from 'lodash.debounce';

import { Form as VeeForm } from 'vee-validate';
import { useAccountStore } from '@/stores/account';
import { useGetReadyProgramStore } from '@/stores/getReadyProgram';
import { useGlobalStore } from '@/stores/global';
import { useTranslationStore } from '@/stores/translation';
import { useToast } from '@/composables/useToast';
import { useUtils } from '@/composables/useUtils';

import AppButtonWithLed from '@/components/misc/AppButtonWithLed.vue';
import AppFormGroupWithValidation from '@/components/misc/AppFormGroupWithValidation.vue';
import GetReadyProgramUserHistory from '@/components/onboarding/GetReadyProgramUserHistory.vue';
import type { EntityDetailsEntityProp } from '@/components/management/EntityDetails.vue';

const props = defineProps({
  isForCrewMember: {
    type: Boolean,
    default: false,
  },
  isUserJourneyTalentManual: {
    type: Boolean,
    default: false,
  },
  isUserJourneyV3: {
    type: Boolean,
    default: false,
  },
  isForHeader: {
    type: Boolean,
    default: false,
  },
  isForTable: {
    type: Boolean,
    default: false,
  },
  isForAccountDetails: {
    type: Boolean,
    default: false,
  },
  page: {
    type: Number,
    required: true,
  },
  role: {
    type: String,
    default: '',
  },
  entity: {
    type: Object as PropType<EntityDetailsEntityProp>,
    default: null,
  },
});

const emit = defineEmits<{
  updatedOnboardingStep: [];
}>();

const $log: any = inject('$log');
const $http: undefined | AxiosStatic = inject('$http');
const toast = useToast();
const accountStore = useAccountStore();
const getReadyProgramStore = useGetReadyProgramStore();
const globalStore = useGlobalStore();
const translationStore = useTranslationStore();
const utils = useUtils();

const busy = ref({
  saving: false,
});
const isEditOnboardingStepModalVisible = ref(false);
const isOnboardingStepHistoryModalVisible = ref(false);

export interface PhaseKeyStepKeyStep {
  phaseKey: string;
  stepKey: string;
  step: number;
}
const selectedStepToSave = ref<PhaseKeyStepKeyStep | null>(null); // { phaseKey, stepKey, step }

const ledToAdjustWidthRefs = ref<HTMLElement[]>([]);

function setLedToAdjustWidthRefs(el: any) {
  ledToAdjustWidthRefs.value.push(el);
}

interface Phase {
  [key: string]: number; // P1_WELCOME: 1001
}

export interface PagesFiltered {
  PHASE_1: Phase;
  PHASE_2: Phase;
  PHASE_3: Phase;
  PHASE_4: Phase;
}

const userJourneyPagesFiltered = computed((): PagesFiltered => {
  let pagesToExclude: number[] = [];
  const unknownErrorPage = getReadyProgramStore.pages.PHASE_2.P2_QUESTIONNAIRE_UNKNOWN_ERROR;
  if (!(props.page === unknownErrorPage || isOnboardingStepHistoryModalVisible.value)) {
    // Ignore the "Unknown Error" page if the user is not there and the modal is closed
    // That is, show the "Unknown Error" page is the modal is open (the user might have been there) or if the user is there
    pagesToExclude.push(unknownErrorPage);
  }

  if (globalStore.isThemeV3 || props.isUserJourneyV3) {
    return {
      PHASE_1: Object.keys(getReadyProgramStore.pages.PHASE_2) // ['P1_WELCOME', 'P1_THE_FOUR_JOURNEY_PHASES', …]
        .filter((key) => !pagesToExclude.includes(getReadyProgramStore.pages.PHASE_2[key as keyof typeof getReadyProgramStore.pages.PHASE_2]) && key.startsWith('P1_'))
        .reduce((res, key) => Object
          .assign(res, { [key]: getReadyProgramStore.pages.PHASE_2[key as keyof typeof getReadyProgramStore.pages.PHASE_2] }), {}),
      //
      //
      PHASE_2: Object.keys(getReadyProgramStore.pages.PHASE_2)
        .filter((key) => !pagesToExclude.includes(getReadyProgramStore.pages.PHASE_2[key as keyof typeof getReadyProgramStore.pages.PHASE_2]) && key.startsWith('P2_'))
        .reduce((res, key) => Object
          .assign(res, { [key]: getReadyProgramStore.pages.PHASE_2[key as keyof typeof getReadyProgramStore.pages.PHASE_2] }), {}),
      //
      //
      PHASE_3: Object.keys(getReadyProgramStore.pages.PHASE_2)
        .filter((key) => !pagesToExclude.includes(getReadyProgramStore.pages.PHASE_2[key as keyof typeof getReadyProgramStore.pages.PHASE_2]) && key.startsWith('P3_'))
        .reduce((res, key) => Object
          .assign(res, { [key]: getReadyProgramStore.pages.PHASE_2[key as keyof typeof getReadyProgramStore.pages.PHASE_2] }), {}),
      //
      //
      PHASE_4: Object.keys(getReadyProgramStore.pages.PHASE_2)
        .filter((key) => !pagesToExclude.includes(getReadyProgramStore.pages.PHASE_2[key as keyof typeof getReadyProgramStore.pages.PHASE_2]) && key.startsWith('P4_'))
        .reduce((res, key) => Object
          .assign(res, { [key]: getReadyProgramStore.pages.PHASE_2[key as keyof typeof getReadyProgramStore.pages.PHASE_2] }), {}),
    };
  }

  if (props.isUserJourneyTalentManual) {
    pagesToExclude = [];
    const unknownErrorPage = getReadyProgramStore.oldJgoPages.PHASE_3.QUESTIONNAIRE_UNKNOWN_ERROR;
    if (!(props.page === unknownErrorPage || isOnboardingStepHistoryModalVisible.value)) {
      // Ignore the "Unknown Error" page if the user is not there and the modal is closed
      // That is, show the "Unknown Error" page is the modal is open (the user might have been there) or if the user is there
      pagesToExclude.push(unknownErrorPage);
    }
    return {
      PHASE_1: Object.keys(getReadyProgramStore.oldJgoPages.PHASE_1)
        .filter((key) => !pagesToExclude.includes(getReadyProgramStore.oldJgoPages.PHASE_1[key as keyof typeof getReadyProgramStore.oldJgoPages.PHASE_1]))
        .reduce((res, key) => Object
          .assign(res, { [key]: getReadyProgramStore.oldJgoPages.PHASE_1[key as keyof typeof getReadyProgramStore.oldJgoPages.PHASE_1] }), {}),
      //
      //
      PHASE_2: Object.keys(getReadyProgramStore.oldJgoPages.PHASE_2)
        .filter((key) => !pagesToExclude.includes(getReadyProgramStore.oldJgoPages.PHASE_2[key as keyof typeof getReadyProgramStore.oldJgoPages.PHASE_2]))
        .reduce((res, key) => Object
          .assign(res, { [key]: getReadyProgramStore.oldJgoPages.PHASE_2[key as keyof typeof getReadyProgramStore.oldJgoPages.PHASE_2] }), {}),
      //
      //
      PHASE_3: Object.keys(getReadyProgramStore.oldJgoPages.PHASE_3)
        .filter((key) => !pagesToExclude.includes(getReadyProgramStore.oldJgoPages.PHASE_3[key as keyof typeof getReadyProgramStore.oldJgoPages.PHASE_3]))
        .reduce((res, key) => Object
          .assign(res, { [key]: getReadyProgramStore.oldJgoPages.PHASE_3[key as keyof typeof getReadyProgramStore.oldJgoPages.PHASE_3] }), {}),
      //
      //
      PHASE_4: Object.keys(getReadyProgramStore.oldJgoPages.PHASE_4)
        .filter((key) => !pagesToExclude.includes(getReadyProgramStore.oldJgoPages.PHASE_4[key as keyof typeof getReadyProgramStore.oldJgoPages.PHASE_4]))
        .reduce((res, key) => Object
          .assign(res, { [key]: getReadyProgramStore.oldJgoPages.PHASE_4[key as keyof typeof getReadyProgramStore.oldJgoPages.PHASE_4] }), {}),
    };
  }
  // Should not reach this
  return {
    PHASE_1: {},
    PHASE_2: {},
    PHASE_3: {},
    PHASE_4: {},
  };
});
const translationPrefix = computed(() => {
  if (props.isUserJourneyTalentManual) {
    return 'userJourneyPageNames.userJourneyTalentManual';
  }
  return 'userJourneyPageNames.userJourneyV3';
});
const stepDropdownText = computed(() => {
  if (selectedStepToSave.value) {
    return `${selectedStepToSave.value.step} - ${translationStore.t(`${translationPrefix.value}.${selectedStepToSave.value.phaseKey}.${selectedStepToSave.value.stepKey}`)}`;
  }
  return '';
});
const willCreateANewQuestionnaireAttempt = computed(() => {
  const QUESTIONNAIRE_STEP = 3052;
  // User is past the questionnaire and will do it again?
  return props.page > QUESTIONNAIRE_STEP && selectedStepToSave.value && selectedStepToSave.value.step <= QUESTIONNAIRE_STEP;
});

const adjustLedDivWidth = debounce(() => {
  const divs = ledToAdjustWidthRefs.value;
  if (!divs || !divs.length) {
    $log.debug('adjustLedDivWidth: no "ledToAdjustWidth" ref?');
    return;
  }
  const baseFontSize = Number.parseFloat(window.getComputedStyle(document.documentElement).fontSize);

  const firstDiv = divs[0];
  const style = window.getComputedStyle(firstDiv);
  const remWidth = Number.parseFloat(style.width) / baseFontSize;
  const remMarginLeft = Number.parseFloat(style.marginLeft) / baseFontSize;
  const remMarginRight = Number.parseFloat(style.marginRight) / baseFontSize;
  const pixelWidth = `${Math.round(remWidth * baseFontSize)}px`;
  const pixelMarginLeft = `${Math.round(remMarginLeft * baseFontSize)}px`;
  const pixelMarginRight = `${Math.round(remMarginRight * baseFontSize)}px`;
  $log.debug(`adjustLedDivWidth: LEDs had ${style.width} with margin of ${style.marginLeft}; will be set to ${pixelWidth} and margin of ${pixelMarginLeft}`);

  divs.forEach((div) => {
    div.style.width = pixelWidth;
    div.style.marginLeft = pixelMarginLeft;
    div.style.marginRight = pixelMarginRight;
  });
}, 500);

onMounted(() => {
  adjustLedDivWidth(); // first time
  window.addEventListener('resize', adjustLedDivWidth);
});

onUnmounted(() => {
  // cancel the debounce timer when the component is removed
  adjustLedDivWidth.cancel();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', adjustLedDivWidth);
});

function phasePercentCompleted(phase: Phase) {
  const values = Object.values(phase);
  const currentIndex = values.indexOf(props.page);
  const size = values.length;
  const percent = ((currentIndex + 1) / size) * 100;
  return `${percent}%`;
}

function isPastOrCurrentPage(phaseStep: number) {
  return phaseStep <= props.page;
}

function isCurrentPageAndNotLastOfAll(step: number, stepIndex: number, phase: Phase, phaseIndex: number) {
  const isLastOfPhase = Object.keys(phase).length === stepIndex + 1;
  const isLastPhase = Object.keys(userJourneyPagesFiltered.value).length === phaseIndex + 1;
  return props.page === step && !(isLastOfPhase && isLastPhase);
}

function currentPageIsAtPhase(phase: Phase) {
  const firstOfPhase = Object.values(phase)[0];
  const lastOfPhase = Object.values(phase).slice(-1)[0];
  // $log.debug('phaseKey', phaseKey, 'first', firstOfPhase, 'last', lastOfPhase);
  return props.page >= firstOfPhase && props.page < lastOfPhase; // do not return true if is last step, as we want a different style for that
}

function tooltipFor(phaseKey: keyof PagesFiltered, stepKey: string) {
  let timeForPhase = '';
  let timeForCertificate = '';
  let phaseName;
  if (translationStore.te(`userJourney.phaseAndStepIndicator.phaseNames.${phaseKey}.${accountStore.userRoleCode}`)) {
    phaseName = translationStore.t(`userJourney.phaseAndStepIndicator.phaseNames.${phaseKey}.${accountStore.userRoleCode}`);
  } else if (props.role && translationStore.te(`userJourney.phaseAndStepIndicator.phaseNames.${phaseKey}.${props.role}`)) {
    phaseName = translationStore.t(`userJourney.phaseAndStepIndicator.phaseNames.${phaseKey}.${props.role}`);
  } else {
    phaseName = translationStore.t(`userJourney.phaseAndStepIndicator.phaseNames.${phaseKey}`);
  }
  if (!props.isForTable && !props.isForAccountDetails) {
    switch (phaseKey) {
      case 'PHASE_1':
        timeForPhase = '00:10';
        if (accountStore.userRoleIsImpTeamLeader) {
          timeForCertificate = '02:00';
        } else {
          // B-User
          timeForCertificate = '01:40';
        }
        break;
      case 'PHASE_2':
        timeForPhase = '01:10';
        if (accountStore.userRoleIsImpTeamLeader) {
          timeForCertificate = '01:50';
        } else {
          // B-User
          timeForCertificate = '01:30';
        }
        break;
      case 'PHASE_3':
        timeForPhase = '00:20';
        if (accountStore.userRoleIsImpTeamLeader) {
          timeForCertificate = '00:40';
        } else {
          // B-User
          timeForCertificate = '00:20';
        }
        break;
      case 'PHASE_4':
        timeForPhase = '00:20';
        timeForCertificate = '00:20';
        break;
      // no default
    }
  }

  const PHRASE_PHASE = `${translationStore.t('userJourney.phaseAndStepIndicator.titlePhase')}: ${phaseName}`;
  const PHRASE_TIME_FOR_PHASE = `${translationStore.t('userJourney.phaseAndStepIndicator.titleTimeToCompletePhase')}: ${timeForPhase}`;
  const PHRASE_TIME_FOR_CERTIFICATE = `${accountStore.userRoleIsImpTeamLeader ? translationStore.t('userJourney.phaseAndStepIndicator.titleTimeToCaptainCertificate') : translationStore.t('userJourney.phaseAndStepIndicator.titleTimeToCrewMemberCertificate')}: ${timeForCertificate}`;
  const translationStepNameKey = `${translationPrefix.value}.${phaseKey}.${stepKey}`;

  const PHRASE_STEP_TITLE = `${translationStore.t('userJourney.phaseAndStepIndicator.titleStep')}: ${translationStore.t(translationStepNameKey)}`;

  if (props.isUserJourneyTalentManual || globalStore.isThemeV3 || props.isUserJourneyV3) {
    //
    // User Journey Talent Manual:
    // No phase names and no time remaining
    //
    return {
      content: `${PHRASE_STEP_TITLE}`,
      popperClass: 'phase-and-step-tooltip',
    };
  }

  if (props.isForTable || props.isForAccountDetails) {
    return {
      content: `${PHRASE_PHASE}
          <br>
          ${PHRASE_STEP_TITLE}`,
      popperClass: 'phase-and-step-tooltip',
    };
  }

  return {
    content: `${PHRASE_PHASE}
          <br>
          <br>
          ${PHRASE_TIME_FOR_PHASE}
          <br>
          <br>
          ${PHRASE_TIME_FOR_CERTIFICATE}
          <br>
          <br>
          ${PHRASE_STEP_TITLE}`,
    popperClass: 'phase-and-step-tooltip',
  };
}

function selectCurrentStep() {
  Object.entries(userJourneyPagesFiltered.value).forEach(([phaseKey, phase]) => {
    Object.entries(phase).forEach(([stepKey, step]) => {
      if (step === props.page) {
        // Found
        selectedStepToSave.value = {
          phaseKey,
          stepKey,
          step,
        };
      }
    });
  });
}

function resetData() {
  busy.value.saving = false;
  selectCurrentStep();
}

function showModalEditOnboardingStep() {
  resetData();
  isEditOnboardingStepModalVisible.value = true;
}

function showModalOnboardingStepHistory() {
  isOnboardingStepHistoryModalVisible.value = true;
}

function selectStepOnDropdown(step: PhaseKeyStepKeyStep) {
  $log.debug('selectStepOnDropdown', step);
  selectedStepToSave.value = step;
}

function onInvalidSubmit({ errors }: { errors: object }) {
  const toastMessage = `${Object.values(errors).flat().join(', ')}`;
  toast.showToastError(toastMessage);
  throw new Error(`Form has errors: ${toastMessage}`);
}

async function saveStep() {
  if (!props.entity) {
    $log.error('saveStep: no entity?', props.entity);
    return;
  }
  if (!('id' in props.entity)) {
    $log.error('saveStep: entity has no id?', props.entity);
    return;
  }
  if (!('account' in props.entity && props.entity.account)) {
    $log.error('saveStep: entity has no account?', props.entity);
    return;
  }
  if (!selectedStepToSave.value || !selectedStepToSave.value.step) {
    $log.error('saveStep: no selectedStepToSave?', selectedStepToSave.value);
    return;
  }
  busy.value.saving = true;
  const accountId = props.entity.account.id;
  const userId = props.entity.id;
  try {
    await $http?.put(`/users/${userId}/onboarding_step`, { onboarding_step: selectedStepToSave.value.step });
    if (willCreateANewQuestionnaireAttempt.value) {
      const REQUEST_TYPE_QUESTIONNAIRE = 2;
      const QUESTIONNAIRE_REQUEST_STATUS = {
        PENDING: 1,
        APPROVED: 2,
        REJECTED: 3,
      };
      const postData = {
        user_id: userId,
        account_id: accountId,
        user_request_type_id: REQUEST_TYPE_QUESTIONNAIRE,
        message: 'auto',
        user_request_status_id: QUESTIONNAIRE_REQUEST_STATUS.PENDING,
      };
      const createRequestResponse = await $http?.post('/requests', postData);
      if (createRequestResponse && 'data' in createRequestResponse.data) {
        const newRequest = createRequestResponse.data.data;
        if (newRequest.status_id === QUESTIONNAIRE_REQUEST_STATUS.PENDING) {
          // Created; Accept it
          await $http?.put(`/requests/${newRequest.id}`, { status_id: QUESTIONNAIRE_REQUEST_STATUS.APPROVED });
        }
      }
    }
    toast.showToastSuccess('Saved');
    emit('updatedOnboardingStep');
    isEditOnboardingStepModalVisible.value = false;
  } catch (error) {
    let allErrors = error;
    let message = 'Could not save the step';
    if (error instanceof AxiosError
      && error.response
      && error.response.data
      && error.response.data.errors) {
      allErrors = utils.getPrintableCommaSeparatedErrors(error.response.data.errors);
    }
    message = `${message}: ${allErrors}`;
    toast.showToastError(message);
    throw new Error(message);
  } finally {
    busy.value.saving = false;
  }
}

defineExpose({
  userJourneyPagesFiltered,
  translationPrefix,
});
</script>

<style
  lang="scss"
  scoped
>
.user-journey-status-indicator {
  display: flex;
  //justify-content: center;
  width: fit-content;
  margin-bottom: $gap-1x;
  cursor: default;

  .theme-v3e & {
    @extend .neumorphic-bg-and-shadow-1-selected;
    padding: 0 0.2rem;
  }

  &.is-for-header {
    margin-bottom: 0;
  }

  &.is-for-table {
    margin-bottom: 0;
    justify-content: left;

    .phase-wrapper {
      margin: 0;
    }

    .phase {
      background-image: linear-gradient(to bottom, color.adjust(#51585d, $lightness: -10%, $space: hsl), color.adjust(#44494e, $lightness: -5%, $space: hsl));
      border: 1px solid var(--columns-background);

      .theme-v3e & {
        background-image: none;
        background-color: transparent;
        border: none;
      }
    }
  }

  &.clickable {
    cursor: pointer; // because of the popover
  }
}

.phase-wrapper {
  //margin: 0 0.2vw;
  //margin: 0 0.17rem;
  user-select: none; // prevent text selection

  //padding: 3px;
  padding: 0.18rem 0.2rem;
  border-radius: $border-radius-3px;

  height: calc(#{$form-item-height} / 1.32);
  background-color: $dark;

  align-self: center; // in case the height is bigger (if we show buttons next to this)

  &:not(:first-child) {
    margin-left: 0.3rem;

    .theme-v3e & {
      margin-left: 0;
    }
  }

  .theme-v3e & {
    height: calc(#{$form-item-height} / 1.32);
    background-color: transparent;
  }

  .phase {
    height: 100%;
    //padding: 0.2vw;
    //padding: 0.17rem;

    border-radius: $border-radius-3px;
    /*box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.18), inset -2px -2px 1px 3px $dark, inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);*/

    display: flex;

    background-color: $dark;

    .theme-v3e & {
      background-image: none;
      background-color: transparent;
    }
  }
}

.led-wrapper {
  height: 100%;

  .led {
    position: relative; // needed for the absolute position of .percent-completed-over-led
    width: 0.20rem;
    height: 100%;
    margin: 0 0.15rem;
    border-radius: $border-radius-3px;
    background-color: var(--default);
    //display: table; // breaks GetReady progress indicator at tables
    //padding: 0 0.08vw;
    //padding: 0 0.07rem;
    transition: background-color .4s ease, box-shadow .4s ease-out;

    .app-imp & {
      --default: #{$theme-v3e-blue-2};
    }

    .app-jgo & {
      --default: #{$darker-grey-background};
    }

    .app-tly & {
      --default: #{$theme-v3e-blue-2};
    }

    &.finished-phase {
      // Same as when active
      background-color: var(--accent-color);
    }

    &.current-page {
      background: linear-gradient(0deg, var(--accent-color) 50%, var(--default) 50%);
    }

    .percent-completed-over-led {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: var(--percentCompleted);
      background-color: var(--accent-color);
    }
  }
}

:deep(.dropdown-menu) {
  width: 100%;
  height: 20rem;
  overflow: scroll;
}
</style>
