// Pinia Store
import { computed, inject, ref } from 'vue';
import type { AxiosStatic } from 'axios';
import { defineStore } from 'pinia';
import i18next from 'i18next';
import type { TOptions } from 'i18next';

import { configure } from 'vee-validate';
import { localize, setLocale } from '@vee-validate/i18n';
import { useAuthStore } from '@/stores/auth';
// import VeeValidateLocaleEn from 'vee-validate/dist/locale/en.json'; // Vue 2
// import VeeValidateLocaleEn from '@vee-validate/i18n/dist/locale/en.json'; // Vue 3
import VeeValidateLocaleEn from '@/lang/vee-validate-custom-en.json';
// import VeeValidateLocaleDe from 'vee-validate/dist/locale/de.json'; // Vue 2
// import VeeValidateLocaleDe from '@vee-validate/i18n/dist/locale/de.json'; // Vue 3
import VeeValidateLocaleDe from '@/lang/vee-validate-custom-de.json';

import { formatDate } from '@/date-fns';
import { useDates } from '@/composables/useDates';

type $SpecialObject = object | Array<string | object>; // from 'i18next/typescript/helpers.d.ts'

const { getDateObjInLocalTime } = useDates();

// import theDictionary from '@/stores/translationDictionary';

console.log('Pinia Translation store is being created.'); // no access to Vue.prototype.$log here yet

const defaultLocaleCode = 'en-GB';

// Feature detect + local reference
let storage: Storage | null;
let fail;
let uid;
try {
  uid = new Date().toString();
  (storage = window.localStorage).setItem(uid, uid);
  fail = storage.getItem(uid) !== uid.toString();
  storage.removeItem(uid);
  if (fail) {
    storage = null;
  }
} catch (exception) {
  const exceptionName = exception && typeof exception === 'object' && 'name' in exception && exception.name;
  console.log(`Could not access the local storage: ${exceptionName}`);
}
//

// function convertShortToLong(language) {
//   switch (language) {
//     case 'en':
//       return 'en-GB';
//     case 'de':
//       return 'de-DE';
//     // case 'fr':
//     //   return 'fr-FR';
//     default:
//       return language;
//   }
// }

// // eslint-disable-next-line no-unused-vars
// function getFirstBrowserLanguage() {
//   const nav = window.navigator;
//   const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
//   let i;
//   let language;
//   let len;
//   let shortLanguage = null;
//
//   // support for HTML 5.1 "navigator.languages"
//   if (Array.isArray(nav.languages)) {
//     $log.debug('nav.languages', nav.languages);
//     for (i = 0; i < nav.languages.length; i += 1) {
//       language = nav.languages[i];
//       language = convertShortToLong(language);
//       len = language.length;
//       if (!shortLanguage && len) {
//         shortLanguage = language;
//       }
//       if (language && len > 2) {
//         return language;
//       }
//     }
//   }
//
//   // support for other well known properties in browsers
//   for (i = 0; i < browserLanguagePropertyKeys.length; i += 1) {
//     language = nav[browserLanguagePropertyKeys[i]];
//     $log.debug(`language from browserLanguagePropertyKeys, key ${browserLanguagePropertyKeys[i]}`, language);
//     if (!language) {
//       // e.g. on PhantomJS tests
//       return '';
//     }
//     language = convertShortToLong(language);
//     len = language.length;
//     if (!shortLanguage && len) {
//       shortLanguage = language;
//     }
//     if (language && len > 2) {
//       return language;
//     }
//   }
//
//   return shortLanguage;
// }

interface LanguagesResponse {
  data: {
    data: {
      date_format: string;
      id: number;
      lang: string;
      language: string;
      name: string;
    }[];
  };
}

export interface ConfigElementRaw {
  catchwords: string;
  color: string;
  description: string;
  id: 1 | 2 | 3 | 4;
  name: string; // Fire, Earth, Water, Air
}

export interface Locale {
  id: number;
  name: string;
  code: string;
}

export interface SimpleTalentManualArea {
  abbr: string;
  description: null | string;
  id: number;
  required: boolean;
  slug: string;
  title: string;
}

const appName = import.meta.env.VITE_APP_NAME;
const isAppImp = appName === 'imp' || appName === 'zly'; // 2022-04-13: added zly here to help with the creation of zly
const isAppJgo = appName === 'jgo';
const isAppTly = appName === 'tly' || appName === 'zae'; // TODO: remove zae
const isAppZly = appName === 'zly';
const isAppZae = appName === 'zae';

const LOCALES = {
  ENGLISH: 'en-GB',
  DEUTSCH: 'de-DE',
};

interface AdjectiveRaw {
  id: number;
  name: string;
  description: string;
  color: string;
  element_left_id: number;
  element_right_id: number;
  catchwords: string;
}

const useTranslationStore = defineStore('translation', () => {
  const $log: any = inject('$log');
  const $http: undefined | AxiosStatic = inject('$http');
  // const $t = inject('$t');

  const supportedLocales = ref<Locale[]>([
    // { name: 'English', code: 'en-GB' },
    // { name: 'Deutsch', code: 'de-DE' },
    // { name: 'Français', code: 'fr-FR' },
  ]);
  const supportedLocalesTalentManual = ref<Locale[]>([]);
  const supportedLocalesQuestionnaire = ref<Locale[]>([]);
  const supportedLocalesQualityCheck = ref<Locale[]>([]);
  const localeCode = ref(defaultLocaleCode);
  const localeTalentManual = ref<null | string>(null);
  const localeQuestionnaire = ref<null | string>(null);
  const localeQualityCheck = ref<null | string>(null);
  const elementsTexts = ref<{
    [localeCode: string]: ConfigElementRaw[];
  }>({});
  const elementsAdjectivesTexts = ref<{
    [localeCode: string]: AdjectiveRaw[];
  }>({});
  // const logbookHelp = ref<>({});
  // const impulseStatus = ref<>({});
  const talentManualAreas = ref<{
    [localeCode: string]: SimpleTalentManualArea[];
  }>({});

  const localeCodeEnglish = computed(() => LOCALES.ENGLISH);
  const localeCodeDeutsch = computed(() => LOCALES.DEUTSCH);
  const isLocaleEnglish = computed(() => localeCode.value === LOCALES.ENGLISH);
  const isLocaleDeutsch = computed(() => localeCode.value === LOCALES.DEUTSCH);
  const elementText = computed(() => (elementName: string) => {
    if (!elementsTexts.value[localeCode.value]) {
      $log.debug('Elements texts translations do not exist yet.');
      return {};
    }
    // Convert element name to ID
    let id: undefined | number;
    switch (elementName.toLowerCase()) {
      case 'fire':
        id = 1;
        break;
      case 'air':
        id = 4;
        break;
      case 'water':
        id = 3;
        break;
      case 'earth':
        id = 2;
        break;
      // no default
    }
    if (!id) {
      $log.error(`Could not convert element name ${elementName} to an ID`);
      return {
        name: '',
        catchwords: '',
        description: '',
      };
    }
    return elementsTexts.value[localeCode.value].find((element) => element.id === id);
  });
  const elementAdjectiveText = computed(() => (elementAdjectiveName: string) => {
    // Convert element name to ID
    let id: undefined | number;
    switch (elementAdjectiveName.toLowerCase()) {
      case 'dry':
        id = 2;
        break;
      case 'hot':
        id = 1;
        break;
      case 'wet':
        id = 4;
        break;
      case 'cold':
        id = 3;
        break;
      // no default
    }
    if (!id) {
      $log.error(`Could not convert element adjective name ${elementAdjectiveName} to an ID`);
      return {
        name: '',
        catchwords: '',
        description: '',
      };
    }
    return elementsAdjectivesTexts.value[localeCode.value].find((elementAdjective) => elementAdjective.id === id);
  });
  // const logbookHelp = computed(() => logbookHelp.value[localeCode.value],
  // const impulseStatusForCurrentLocale = computed(() => impulseStatus.value[localeCode.value],
  const talentManualAreasForCurrentLocale = computed(() => talentManualAreas.value[localeCode.value]); // e.g. for talent manual invitation details

  //
  //
  //
  const brandTranslation = computed((): string => {
    if (isAppZae) {
      return i18next.t('substitutions.brand-zae').toString();
    }
    if (isAppZly) {
      return i18next.t('substitutions.brand-zly').toString();
    }
    if (isAppImp) {
      return i18next.t('substitutions.brand-imp').toString();
    }
    if (isAppJgo) {
      return i18next.t('substitutions.brand-jgo').toString();
    }
    if (isAppTly) {
      return i18next.t('substitutions.brand-tly').toString();
    }
    return '';
  });
  const brandTranslationTitleCase = computed((): string => {
    if (isAppZae) {
      return i18next.t('substitutions.brand-zae-title-case').toString();
    }
    if (isAppZly) {
      return i18next.t('substitutions.brand-zly-title-case').toString();
    }
    if (isAppImp) {
      return i18next.t('substitutions.brand-imp-title-case').toString();
    }
    if (isAppJgo) {
      return i18next.t('substitutions.brand-jgo-title-case').toString();
    }
    if (isAppTly) {
      return i18next.t('substitutions.brand-tly-title-case').toString();
    }
    return '';
  });
  const brandClaimTranslation = computed((): string => {
    if (isAppZae) {
      return i18next.t('substitutions.brand-claim-zae').toString();
    }
    if (isAppZly) {
      return i18next.t('substitutions.brand-claim-zly').toString();
    }
    if (isAppImp) {
      return i18next.t('substitutions.brand-claim-imp').toString();
    }
    if (isAppJgo) {
      return i18next.t('substitutions.brand-claim-jgo').toString();
    }
    if (isAppTly) {
      return i18next.t('substitutions.brand-claim-tly').toString();
    }
    return '';
  });
  const companyTranslation = computed((): string => {
    if (isAppZae) {
      return i18next.t('substitutions.company-zae').toString();
    }
    if (isAppZly) {
      return i18next.t('substitutions.company-zly').toString();
    }
    if (isAppImp) {
      return i18next.t('substitutions.company-imp').toString();
    }
    if (isAppJgo) {
      return i18next.t('substitutions.company-jgo').toString();
    }
    if (isAppTly) {
      return i18next.t('substitutions.company-tly').toString();
    }
    return '';
  });
  const talentManualTranslationShort = computed((): string => {
    if (isAppZae) {
      return i18next.t('substitutions.tm-zae-short').toString();
    }
    if (isAppZly) {
      return i18next.t('substitutions.tm-zly-short').toString();
    }
    if (isAppImp) {
      return i18next.t('substitutions.tm-imp-short').toString();
    }
    if (isAppJgo) {
      return i18next.t('substitutions.tm-jgo-short').toString();
    }
    if (isAppTly) {
      return i18next.t('substitutions.tm-tly-short').toString();
    }
    return '';
  });
  const talentManualTranslationLong = computed((): string => {
    if (isAppZae) {
      return i18next.t('substitutions.tm-zae-long').toString();
    }
    if (isAppZly) {
      return i18next.t('substitutions.tm-zly-long').toString();
    }
    if (isAppImp) {
      return i18next.t('substitutions.tm-imp-long').toString();
    }
    if (isAppJgo) {
      return i18next.t('substitutions.tm-jgo-long').toString();
    }
    if (isAppTly) {
      return i18next.t('substitutions.tm-tly-long').toString();
    }
    return '';
  });
  const teamOrCrewTranslation = computed((): string => {
    if (isAppZae) {
      return i18next.t('substitutions.team-or-crew-zae').toString();
    }
    if (isAppZly) {
      return i18next.t('substitutions.team-or-crew-zly').toString();
    }
    if (isAppImp) {
      return i18next.t('substitutions.team-or-crew-imp').toString();
    }
    if (isAppJgo) {
      return i18next.t('substitutions.team-or-crew-jgo').toString();
    }
    if (isAppTly) {
      return i18next.t('substitutions.team-or-crew-tly').toString();
    }
    return '';
  });
  const teamOrCrewLowercaseTranslation = computed((): string => {
    if (isAppZae) {
      return i18next.t('substitutions.team-or-crew-zae-lowercase').toString();
    }
    if (isAppZly) {
      return i18next.t('substitutions.team-or-crew-zly-lowercase').toString();
    }
    if (isAppImp) {
      return i18next.t('substitutions.team-or-crew-imp-lowercase').toString();
    }
    if (isAppJgo) {
      return i18next.t('substitutions.team-or-crew-jgo-lowercase').toString();
    }
    if (isAppTly) {
      return i18next.t('substitutions.team-or-crew-tly-lowercase').toString();
    }
    return '';
  });
  const participantsOrCandidatesTranslation = computed((): string => {
    // if (isAppImp) {
    //   return i18next.t('substitutions.participants-or-candidates-imp').toString();
    // }
    if (isAppJgo) {
      return i18next.t('substitutions.participants-or-candidates-jgo').toString();
    }
    if (isAppTly) {
      return i18next.t('substitutions.participants-or-candidates-tly').toString();
    }
    return '';
  });
  const participantsOrCandidatesLowercaseTranslation = computed((): string => {
    // if (isAppImp) {
    //   return i18next.t('substitutions.participants-or-candidates-imp-lowercase').toString();
    // }
    if (isAppJgo) {
      return i18next.t('substitutions.participants-or-candidates-jgo-lowercase').toString();
    }
    if (isAppTly) {
      return i18next.t('substitutions.participants-or-candidates-tly-lowercase').toString();
    }
    return '';
  });
  const partnerOrTeamLeaderTranslation = computed((): string => {
    // if (isAppImp) {
    //   return i18next.t('substitutions.x').toString();
    // }
    if (isAppJgo) {
      return i18next.t('substitutions.partner-or-team-leader-jgo').toString();
    }
    if (isAppTly) {
      return i18next.t('substitutions.partner-or-team-leader-tly').toString();
    }
    return '';
  });

  const translationSubstitutions = computed((): { [key: string]: string } => ({
    // the keys are used in the translations files, for example "Welcome to {{brand}}!"
    brand: brandTranslation.value,
    brandTitleCase: brandTranslationTitleCase.value,
    manualLong: talentManualTranslationLong.value,
    manualShort: talentManualTranslationShort.value,
    teamOrCrew: teamOrCrewTranslation.value,
    teamOrCrewLowercase: teamOrCrewLowercaseTranslation.value,
    participantsOrCandidates: participantsOrCandidatesTranslation.value,
    participantsOrCandidatesLowercase: participantsOrCandidatesLowercaseTranslation.value,
    partnerOrTeamLeader: partnerOrTeamLeaderTranslation.value,
  }));

  const t = computed(() => {
    // Mix in the substitutions (e.g. brand name, talent manual)
    // if (!app) {
    //   // no app when running unit tests?
    //   return () => '(no app)';
    // }
    if (localeCode.value) {
      // This "if (localeCode.value)" is here only to force this computed to detect when
      // the locale changes, to fix some pages not updating (e.g. /dev/test-translations)
      return (path: string, options?: TOptions): string => i18next.t(path, { ...translationSubstitutions.value, ...options });
    }
    return (): string => '';
  });

  const tArray = computed(() => {
    // Mix in the substitutions (e.g. brand name, talent manual)
    // if (!app) {
    //   // no app when running unit tests?
    //   return () => ['(no app)'];
    // }
    // returnObjects option returns objects or arrays, so we can do, for example, "v-for="(paragraph, index) in translationStore.tArray('qualityCheck.languageChoice.leftColumnBodyParagraphs')""
    return (path: string, options?: object): $SpecialObject => i18next.t(path, { ...translationSubstitutions.value, returnObjects: true, ...options });
  });

  const te = computed(() => {
    // Check if translation exists ($t on vue-i18n / .exists on i18next)
    return (path: string, options?: TOptions): boolean => i18next.exists(path, { ...options });
  });

  const getLocalFormattedDate = computed(() => {
    return (date: null | undefined | string | Date | number, customFormat?: string) => {
      if (!date) {
        return '';
      }
      /**
       * https://date-fns.org/v2.30.0/docs/format
       *
       * P      dd/mm/yyyy      04/29/1453
       * PP     dd mmm yyyy     Apr 29, 1453
       * PPP                    April 29th, 1453
       * PPPP                   Friday, April 29th, 1453
       *
       * p      HH:mm           12:00
       * pp     HH:mm:ss        12:00:00
       * ppp    HH:mm:ss TZ     12:00:00 AM GMT+2
       * ppp    HH:mm:ss TZ+x   12:00:00 AM GMT+02:00
       *
       * Pp     dd/mm/yyyy HH:mm    04/29/1453, 12:00
       */
      return formatDate(getDateObjInLocalTime(date), customFormat || 'P', localeCode.value);
    };
  });

  const getLocalFormattedTime = computed(() => {
    return (date: undefined | string | Date | number, customFormat?: string) => {
      if (!date) {
        return '';
      }
      return formatDate(getDateObjInLocalTime(date), customFormat || 'p', localeCode.value);
    };
  });

  function resetState() {
    supportedLocales.value = [];
    supportedLocalesTalentManual.value = [];
    supportedLocalesQuestionnaire.value = [];
    supportedLocalesQualityCheck.value = [];
    localeCode.value = defaultLocaleCode;
    localeTalentManual.value = null;
    localeQuestionnaire.value = null;
    localeQualityCheck.value = null;
    elementsTexts.value = {};
    elementsAdjectivesTexts.value = {};
    // logbookHelp.value = {};
    // impulseStatus.value = {};
    talentManualAreas.value = {};
  }

  // function injectApp(appParam: App) {
  //   app = appParam;
  //   // $log.debug('translation: injectApp OK');
  //   console.log('translation: injectApp OK');
  // }

  async function fetchSupportedLocalesSystem() {
    //
    // System & Talent Manual languages
    //
    try {
      const response: undefined | LanguagesResponse = await $http?.get('/config/languages/system', { signal: undefined });
      if (response?.data && response.data.data) {
        const responseData = response.data.data;
        supportedLocales.value = responseData.map((locale) => ({
          id: locale.id,
          name: locale.language,
          code: locale.lang,
        }));
      } else {
        $log.error('fetchSupportedLocalesSystem: no "data" in response.data');
      }
      // resolve();
    } catch (error) {
      $log.error('fetchSupportedLocalesSystem: could not fetch system languages:', error);
      // let proceed anyway
      // resolve();
    } finally {
      //
    }
  }

  function getInitialLanguageFromStorageOrBrowser() {
    if (storage) {
      const langFromLocalStorage = storage.getItem('localeCode');
      $log.debug('locale from local storage', langFromLocalStorage);
      if (langFromLocalStorage) {
        return langFromLocalStorage;
      }
    }

    // const langFromBrowser = getFirstBrowserLanguage();
    const langFromBrowser = false; // disabled (fallback to en-GB) for the 2018-05-31 demo
    $log.debug('locale from browser', langFromBrowser);
    if (langFromBrowser) {
      const found = supportedLocales.value.find((locale) => locale.code === langFromBrowser);
      if (found) {
        $log.debug(`${langFromBrowser} exists. Selecting it.`);
        return langFromBrowser;
      }
    }

    return defaultLocaleCode;
  }

  function changeLangTalentManual(localeCodeArg: string) {
    const localeExists = supportedLocalesTalentManual.value.find((locale) => locale.code === localeCodeArg);
    // Use this new locale if it exists, or keep the previous if it exists, or use the default.
    localeTalentManual.value = (localeExists && localeExists.code) || localeTalentManual.value || defaultLocaleCode;
    // fetchProfileMenuItemsAndSaveTitles();
  }

  function changeLangQuestionnaire(localeCodeArg: string) {
    const localeExists = supportedLocalesQuestionnaire.value.find((locale) => locale.code === localeCodeArg);
    // Use this new locale if it exists, or keep the previous if it exists, or use the default.
    localeQuestionnaire.value = (localeExists && localeExists.code) || localeQuestionnaire.value || defaultLocaleCode;
    $log.info(`Translation store: Questionnaire language changed to ${localeCodeArg}`);
  }

  function changeLangQualityCheck(localeCodeArg: string) {
    const localeExists = supportedLocalesQualityCheck.value.find((locale) => locale.code === localeCodeArg);
    // Use this new locale if it exists, or keep the previous if it exists, or use the default.
    localeQualityCheck.value = (localeExists && localeExists.code) || localeQualityCheck.value || defaultLocaleCode;
    $log.info(`Translation store: Quality Check language changed to ${localeCodeArg}`);
    // Set the Talent Manual to the same language
    changeLangTalentManual(localeQualityCheck.value);
  }

  async function saveLangServerSide(localeId: number) {
    const authStore = useAuthStore();
    if (!authStore.isAuthenticated) {
      $log.debug('Not authenticated, skipping.');
      return;
    }
    await $http?.put('/session/settings', { settings: { system_language_id: localeId } }, { signal: undefined });
  }

  async function fetchSupportedLocalesQuestionnaire() {
    //
    // Questionnaire languages
    //
    try {
      const response: undefined | LanguagesResponse = await $http?.get('/config/languages/questionnaire', { signal: undefined });
      if (response?.data?.data) {
        const responseData = response.data.data;
        supportedLocalesQuestionnaire.value = responseData.map((locale) => ({
          id: locale.id,
          name: locale.language,
          code: locale.lang,
        }));
        // // Select the current language as the questionnaire language, if it exists
        // const localeExists = supportedLocalesQuestionnaire.value.find((locale) => locale.code === localeCode.value);
        // if (localeExists) {
        //   changeLangQuestionnaire(localeExists.code);
        // }
      } else {
        $log.error('fetchSupportedLocalesQuestionnaire: no "data" in response.data');
      }
      // resolve();
    } catch (error) {
      $log.error('Could not fetch questionnaire languages:', error);
      // let proceed anyway
      // resolve();
    } finally {
      //
    }
  }

  async function fetchSupportedLocalesQualityCheckAndTalentManual() {
    //
    // Questionnaire languages
    //
    const authStore = useAuthStore();
    if (!authStore.isAuthenticated) {
      $log.debug('Not authenticated, skipping.');
      return;
    }
    try {
      const response: undefined | LanguagesResponse = await $http?.get('/config/languages/quality_check', { signal: undefined });
      if (response?.data && response.data.data) {
        const locales = response.data.data.map((locale) => ({
          id: locale.id,
          name: locale.language,
          code: locale.lang,
        }));
        supportedLocalesQualityCheck.value = locales;
        supportedLocalesTalentManual.value = locales;
        // Set the Talent Manual language to the system language, if it exists
        changeLangTalentManual(localeCode.value);
        // // Select the current language as the Quality Check language, if it exists
        // const localeExists = supportedLocalesQualityCheck.value.find((locale) => locale.code === localeCode.value);
        // if (localeExists) {
        //   changeLangQualityCheck(localeExists.code);
        // }
      } else {
        $log.error('fetchSupportedLocalesQualityCheckAndTalentManual: no "data" in response.data');
      }
      // resolve();
    } catch (error) {
      $log.error('Could not fetch quality check / talent manual languages:', error);
      // let proceed anyway
      // resolve();
    } finally {
      //
    }
  }

  async function fetchConfigElements() {
    //
    // Get elements texts
    //
    if (!(localeCode.value in elementsTexts.value)) {
      // Does not exist yet for this language
      try {
        $log.debug('Will fetch elements');

        interface ConfigElementsResponse {
          data: {
            data: ConfigElementRaw[];
          };
        }

        const response: undefined | ConfigElementsResponse = await $http?.get('/config/elements', { signal: undefined });
        if (!response) {
          return;
        }
        $log.debug('Got elements');
        elementsTexts.value[localeCode.value] = response.data.data;
        $log.debug('fetchConfigElements elementsTexts:', elementsTexts.value);
      } catch (error) {
        $log.error('Translation: could not fetch the elements texts:', error);
      } finally {
        //
      }
    }
  }

  async function fetchConfigElementsAdjectives() {
    if (!(localeCode.value in elementsAdjectivesTexts.value)) {
      // Does not exist yet for this language
      try {
        $log.debug('Will fetch element adjectives');

        interface AdjectivesResponse {
          data: {
            data: AdjectiveRaw[];
          };
        }

        const response: undefined | AdjectivesResponse = await $http?.get('/config/elements/adjectives', { signal: undefined });
        if (!response) {
          return;
        }
        $log.debug('Got element adjectives');
        elementsAdjectivesTexts.value[localeCode.value] = response.data.data;
        $log.debug('fetchConfigElementsAdjectives elementsAdjectivesTexts:', elementsAdjectivesTexts.value);
      } catch (error) {
        $log.error('Translation: could not fetch the elements adjectives texts:', error);
      } finally {
        //
      }
    }
  }

  // async function fetchLogbookHelpAreas() {
  //   //
  //   // Get Logbook help areas
  //   //
  //   if (!(localeCode.value in logbookHelp.value)) {
  //     // Does not exist yet for this language
  //     const LOGBOOK_AREA_ID = 1;
  //     try {
  //       const response = await $http?.get(`/system/help/areas/${LOGBOOK_AREA_ID}`, { signal: undefined });
  //       logbookHelp.value[localeCode.value] = response.data;
  //       $log.debug('fetchLogbookHelpAreas logbookHelp:', logbookHelp.value);
  //     } catch (error) {
  //       $log.error('Translation: could not fetch the logbook help:', error);
  //     } finally {
  //       //
  //     }
  //   }
  // }

  // async function fetchConfigImpulsesStatus() {
  //   //
  //   // Get impulses status
  //   //
  //   if (!(localeCode.value in impulseStatus.value)) {
  //     // Does not exist yet for this language
  //     try {
  //       const response = await $http?.get('/config/impulses/status', { signal: undefined });
  //       impulseStatus.value[localeCode.value] = response.data.data;
  //       $log.debug('fetchConfigImpulsesStatus impulseStatus:', impulseStatus.value);
  //     } catch (error) {
  //       $log.error('Translation: could not fetch the impulse status:', error);
  //     } finally {
  //       //
  //     }
  //   }
  // }

  async function fetchTalentManualAreas() {
    //
    // Get talent manual areas
    //
    if (!(localeCode.value in talentManualAreas.value)) {
      // Does not exist yet for this language
      try {
        interface TalentManualAreasMainResponse {
          data: {
            data: SimpleTalentManualArea[];
          };
        }

        const response: undefined | TalentManualAreasMainResponse = await $http?.get('/config/talent_manual/areas/main', { signal: undefined });
        if (!response) {
          return;
        }
        talentManualAreas.value[localeCode.value] = response.data.data;
        $log.debug('fetchTalentManualAreas talentManualAreas:', talentManualAreas.value);
      } catch (error) {
        $log.error('Translation: could not fetch the talent manual areas:', error);
      } finally {
        //
      }
    }
  }

  async function fetchAuthenticatedConfigTranslations() {
    const authStore = useAuthStore();
    if (!authStore.isAuthenticated) {
      $log.debug('Not authenticated, skipping.');
      return;
    }
    await fetchConfigElements();
    await fetchConfigElementsAdjectives();
    // fetchLogbookHelpAreas();
    // if (process.env.VITE_APP_NAME === 'imp') {
    //   fetchConfigImpulsesStatus();
    // } else {
    //   // other apps do not have impulses
    // }
    await fetchTalentManualAreas();
  }

  async function changeLang(localeCodeArg: string) {
    $log.info(`Translation store: language changed to ${localeCodeArg}`);

    const localeExists = supportedLocales.value.find((locale) => locale.code === localeCodeArg);
    // Use this new locale if it exists, or keep the previous if it exists, or use the default.
    if (!localeExists) {
      $log.warn(`Translation store: locale ${localeCodeArg} does not exist? Will change to the default ${defaultLocaleCode}`);
      localeCode.value = defaultLocaleCode;
    } else {
      localeCode.value = localeExists.code;
    }

    const localeWithId = supportedLocales.value.find((locale) => locale.code === localeCode.value);
    if (localeWithId) {
      saveLangServerSide(localeWithId.id); // must send the ID (e.g. 1, 2) instead of the code (e.g. 'en-GB')
    }

    // Set <html> lang
    document.documentElement.setAttribute('lang', localeCode.value.split('-')[0].toLowerCase());

    if (storage) {
      storage.setItem('localeCode', localeCode.value);
    }

    // i18next:
    await i18next.changeLanguage(localeCode.value);

    // VeeValidate:
    switch (localeCode.value) {
      case 'en-GB':
        // English
        console.log('localizing VeeValidate to en');
        setLocale('en'); // vee-validate: activate the English locale
        break;
      case 'de-DE':
        // Deutsch
        console.log('localizing VeeValidate to de');
        setLocale('de'); // vee-validate: activate the Deutsch locale.
        break;
      // no default
    }

    // Set Talent Manual language to the same as the system
    changeLangTalentManual(localeCode.value);

    const authStore = useAuthStore();
    if (authStore.isAuthenticated) {
      // After sign in, if user has "system_language_id" in settings (account store),
      // changeLangFromID will be called, which will call this.
      await fetchAuthenticatedConfigTranslations();
      await fetchSupportedLocalesQualityCheckAndTalentManual();
    }
  }

  function changeLangFromID(localeId: number) {
    const foundLocale = supportedLocales.value.find((lang) => lang.id === localeId);
    if (foundLocale) {
      changeLang(foundLocale.code);
    }
  }

  async function initialize() {
    if (!supportedLocales.value.length) {
      $log.debug('initialize: no existing supportedLocales, will fetch them');
      await fetchSupportedLocalesSystem();
    }

    // Setup form validation translations
    configure({
      generateMessage: localize({
        en: VeeValidateLocaleEn,
        de: VeeValidateLocaleDe,
      }),
    });

    const initialLocale = getInitialLanguageFromStorageOrBrowser(); // the user is not yet signed in at this moment
    await changeLang(initialLocale);
  }

  return {
    //
    // State
    //
    supportedLocales,
    supportedLocalesTalentManual,
    supportedLocalesQuestionnaire,
    supportedLocalesQualityCheck,
    localeCode,
    localeTalentManual,
    localeQuestionnaire,
    localeQualityCheck,
    elementsTexts,
    elementsAdjectivesTexts,
    // logbookHelp,
    // impulseStatus,
    talentManualAreas,
    //
    // Getters
    //
    localeCodeEnglish,
    localeCodeDeutsch,
    isLocaleEnglish,
    isLocaleDeutsch,
    elementText,
    elementAdjectiveText,
    // logbookHelp,
    // impulseStatusForCurrentLocale,
    talentManualAreasForCurrentLocale,
    brandTranslation,
    brandTranslationTitleCase,
    brandClaimTranslation,
    companyTranslation,
    talentManualTranslationShort,
    talentManualTranslationLong,
    teamOrCrewTranslation,
    teamOrCrewLowercaseTranslation,
    participantsOrCandidatesTranslation,
    participantsOrCandidatesLowercaseTranslation,
    partnerOrTeamLeaderTranslation,
    translationSubstitutions,
    t,
    tArray,
    te,
    getLocalFormattedDate,
    getLocalFormattedTime,
    //
    // Actions
    //
    resetState,
    initialize,
    // fetchSupportedLocalesSystem,
    fetchSupportedLocalesQuestionnaire,
    fetchSupportedLocalesQualityCheckAndTalentManual,
    // getInitialLanguageFromStorageOrBrowser,
    changeLang,
    changeLangFromID,
    changeLangTalentManual,
    changeLangQuestionnaire,
    changeLangQualityCheck,
    // saveLangServerSide,
    // fetchConfigElements,
    // fetchConfigElementsAdjectives,
    // fetchLogbookHelpAreas,
    // fetchConfigImpulsesStatus,
    // fetchTalentManualAreas,
    // fetchAuthenticatedConfigTranslations,
  };
});

export { useTranslationStore };
