<template>
  <div
    :class="[name, {
      active, 'active-without-color': activeWithoutColor, disabled, flip, highlighted, red, 'desaturated-border': desaturatedBorder, 'red-ring': redRing, hoverable, 'not-hoverable': !hoverable, 'v3-background': useV3IconBackground, 'no-background': noBackground,
    }]"
    class="app-icon"
    @click="!disabled && $emit('click', $event)"
  >
    <svg
      v-if="showOuterCircle && !useV3Icon && !useV3IconBackground"
      class="circle"
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 12.358158 12.358511"
    >
      <g
        transform="translate(9.2028886,-39.844555)"
      >
        <g
          transform="matrix(0.35277777,0,0,-0.35277777,2.8024917,46.02381)"
        >
          <path
            :class="{ 'no-background': noBackground }"
            d="m 0,0 c 0,-9.122 -7.394,-16.516 -16.516,-16.516 -9.121,0 -16.515,7.394 -16.515,16.516 0,9.121 7.394,16.516 16.515,16.516 C -7.394,16.516 0,9.121 0,0"
            class="circle-background"
          />
        </g>
        <g
          :class="{ 'no-border': noBorder }"
          transform="matrix(0.35277777,0,0,-0.35277777,2.8024917,46.02381)"
          class="circle-ring"
        >
          <path
            d="m 0,0 c 0,-9.122 -7.394,-16.516 -16.516,-16.516 -9.121,0 -16.515,7.394 -16.515,16.516 0,9.121 7.394,16.516 16.515,16.516 C -7.394,16.516 0,9.121 0,0 Z"
            style="fill:none;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          />
        </g>
      </g>
    </svg>

    <svg
      v-if="useV3IconBackground"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.53 283.53"
      class="icon-v3"
    >
      <circle
        class="svg-background-circle"
        cx="141.76"
        cy="141.76"
        r="141.73"
      />
      <text
        if="textInsideIcon"
        x="50%"
        y="50%"
        transform="translate(0 65)"
        text-anchor="middle"
        font-weight="bold"
        font-size="200"
        fill="white"
      >
        {{ textInsideIcon }}
      </text>
    </svg>

    <template v-if="useV3Icon">
      <!-- v3 icons -->
      <template v-if="active && v3Icons[`${name}-active`]">
        <img
          :src="v3Icons[`${name}-active`]"
          class="icon-v3"
        >
      </template>
      <template v-else>
        <img
          :src="v3Icons[name]"
          class="icon-v3"
        >
      </template>
    </template>

    <template v-else-if="name === 'pencil'">
      <svg
        class="inside-icon pencil"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 35.03 35.03"
      >
        <rect
          x="15.43"
          y="10.57"
          width="4.16"
          height="13.85"
          transform="translate(17.5 -7.26) rotate(45)"
        />
        <path
          d="M23.34,9.43h2a1,1,0,0,1,1,1v1.6a0,0,0,0,1,0,0H22.17a0,0,0,0,1,0,0V10.6A1.16,1.16,0,0,1,23.34,9.43Z"
          transform="translate(14.71 -14) rotate(45)"
        />
        <path
          d="M10.26,21.8l2.94,2.94L9.56,26.16a.56.56,0,0,1-.72-.72Z"
        />
      </svg>
    </template>

    <template v-else-if="name === 'email-at'">
      <!--    <svg-->
      <!--      :class="name"-->
      <!--      class="inside-icon"-->
      <!--      xmlns="http://www.w3.org/2000/svg"-->
      <!--      viewBox="0 0 490.2 490.2"-->
      <!--    >-->
      <!--      <path-->
      <!--        d="M420.95,61.8C376.25,20.6,320.65,0,254.25,0c-69.8,0-129.3,23.4-178.4,70.3s-73.7,105.2-73.7,175-->
      <!--        c0,66.9,23.4,124.4,70.1,172.6c46.9,48.2,109.9,72.3,189.2,72.3c47.8,0,94.7-9.8,140.7-29.5c15-6.4,22.3-23.6,16.2-38.7l0,0-->
      <!--        c-6.3-15.6-24.1-22.8-39.6-16.2c-40,17.2-79.2,25.8-117.4,25.8c-60.8,0-107.9-18.5-141.3-55.6c-33.3-37-50-80.5-50-130.4-->
      <!--        c0-54.2,17.9-99.4,53.6-135.7c35.6-36.2,79.5-54.4,131.5-54.4c47.9,0,88.4,14.9,121.4,44.7s49.5,67.3,49.5,112.5-->
      <!--        c0,30.9-7.6,56.7-22.7,77.2c-15.1,20.6-30.8,30.8-47.1,30.8c-8.8,0-13.2-4.7-13.2-14.2c0-7.7,0.6-16.7,1.7-27.1l18.6-152.1h-64-->
      <!--        l-4.1,14.9c-16.3-13.3-34.2-20-53.6-20c-30.8,0-57.2,12.3-79.1,36.8c-22,24.5-32.9,56.1-32.9,94.7c0,37.7,9.7,68.2,29.2,91.3-->
      <!--        c19.5,23.2,42.9,34.7,70.3,34.7c24.5,0,45.4-10.3,62.8-30.8c13.1,19.7,32.4,29.5,57.9,29.5c37.5,0,69.9-16.3,97.2-49-->
      <!--        c27.3-32.6,41-72,41-118.1C488.05,152.9,465.75,103,420.95,61.8z M273.55,291.9c-11.3,15.2-24.8,22.9-40.5,22.9-->
      <!--        c-10.7,0-19.3-5.6-25.8-16.8c-6.6-11.2-9.9-25.1-9.9-41.8c0-20.6,4.6-37.2,13.8-49.8s20.6-19,34.2-19c11.8,0,22.3,4.7,31.5,14.2-->
      <!--        s13.8,22.1,13.8,37.9C290.55,259.2,284.85,276.6,273.55,291.9z"-->
      <!--      />-->
      <!--    </svg>-->
      <template v-if="globalStore.isNotThemeV3">
        <FontAwesomeIcon
          :class="name"
          icon="at"
          class="inside-icon"
        />
      </template>
      <template v-else>
        <!-- temporary workaround while we don't have the SVG: build the blue circle and add the font awesome icon inside -->
        <FontAwesomeIcon
          :class="name"
          icon="at"
          class="inside-icon"
        />
      </template>
    </template>

    <template v-else-if="name === 'vertical-ellipsis'">
      <!--    <svg-->
      <!--      v-if="name === 'vertical-ellipsis'"-->
      <!--      :class="name"-->
      <!--      class="inside-icon"-->
      <!--      xmlns="http://www.w3.org/2000/svg"-->
      <!--      viewBox="0 0 45.583 45.583"-->
      <!--    >-->
      <!--      <path-->
      <!--        d="M22.793,12.196c-3.361,0-6.078-2.729-6.078-6.099C16.715,2.73,19.432,0,22.793,0c3.353,0,6.073,2.729,6.073,6.097-->
      <!--        C28.866,9.466,26.145,12.196,22.793,12.196z"-->
      <!--      />-->
      <!--      <path-->
      <!--        d="M22.794,28.889c-3.361,0-6.079-2.729-6.079-6.099c0-3.366,2.717-6.099,6.078-6.099c3.353,0,6.073,2.732,6.075,6.099-->
      <!--        C28.866,26.162,26.144,28.889,22.794,28.889z"-->
      <!--      />-->
      <!--      <path-->
      <!--        d="M22.794,45.583c-3.361,0-6.079-2.729-6.079-6.099s2.717-6.098,6.078-6.098c3.353-0.002,6.073,2.729,6.073,6.098-->
      <!--        S26.144,45.583,22.794,45.583z"-->
      <!--      />-->
      <!--    </svg>-->
      <FontAwesomeIcon
        :class="name"
        icon="ellipsis-v"
        class="inside-icon"
      />
    </template>

    <!--    <template v-else-if="name === 'spelling'">-->
    <!--      <FontAwesomeIcon-->
    <!--        :class="name"-->
    <!--        icon="language"-->
    <!--        class="inside-icon"-->
    <!--      />-->
    <!--    </template>-->

    <template v-else-if="name === 'learning-nugget'">
      <!--      <FontAwesomeIcon-->
      <!--        :class="name"-->
      <!--        icon="key"-->
      <!--        class="inside-icon"-->
      <!--      />-->
      <component
        :is="getComponentFor('key-solid')"
        class="inside-icon key-solid"
      />
    </template>

    <template v-else-if="name === 'languages'">
      <FontAwesomeIcon
        :class="name"
        icon="globe"
        class="inside-icon"
      />
    </template>

    <template v-else-if="name === 'knowledge-base'">
      <!--      <FontAwesomeIcon-->
      <!--        :class="name"-->
      <!--        icon="binoculars"-->
      <!--        class="inside-icon"-->
      <!--      />-->
      <component
        :is="getComponentFor('knowledge-base-v2')"
        class="inside-icon knowledge-base-v2"
      />
    </template>

    <template v-else-if="name === 'info'">
      <!-- <FontAwesomeIcon
        :class="name"
        icon="info"
        class="inside-icon"
      /> -->
      <!---->
      <!-- TLY_2023_System_Design_Information_i_Blue_001.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <circle
            fill="#fff"
            cx="147.39"
            cy="76.56"
            r="22.57"
          />
          <path
            fill="#fff"
            d="m108.45,110.57h47.44c7.29,0,12.77,6.66,11.37,13.81l-15.03,76.73c-1.41,7.19,5.56,13.23,12.45,10.73,3.73-1.35,7.52-2.99,10.57-4.87.93-.57,2.03-.85,3.11-.72,1.51.18,3.13,1.12,2.42,4.47-1.24,5.87-14.57,20.79-40.8,18.86-26.73-1.97-28.83-16.16-29.67-21.33-.9-5.5,8.46-54.99,12.69-75.54.97-4.7-2.41-9.17-7.2-9.53l-7.35-.55s-1.61,0-3.33-1.56c-2.79-2.52-2.85-6.98.08-9.33.86-.68,1.93-1.16,3.25-1.16Z"
          />
        </g>
      </svg>
    </template>

    <template v-else-if="name === 'talent-manual-icon'">
      <!--      <FontAwesomeIcon-->
      <!--        :class="name"-->
      <!--        icon="address-book"-->
      <!--        class="inside-icon"-->
      <!--      />-->
      <component
        :is="getComponentFor('talent-manual-icon-v2')"
        class="inside-icon talent-manual-icon-v2"
      />
    </template>

    <template v-else-if="name === 'anticipation-and-reflection'">
      <component
        :is="getComponentFor('reflection-symbol')"
        class="inside-icon anticipation-and-reflection"
      />
    </template>

    <template v-else-if="name === 'logbook'">
      <component
        :is="getComponentFor('logbook-v2_3')"
        class="inside-icon logbook-v2"
      />
    </template>

    <template v-else-if="name === 'charts'">
      <FontAwesomeIcon
        :class="name"
        icon="chart-bar"
        class="inside-icon"
      />
    </template>

    <template v-else-if="name === 'circle-active-mode'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20.59 20.59"
        class="inside-icon circle-active-basic-development"
      >
        <!-- Active Mode -->
        <!--<g>-->
        <!--<circle cx="50" cy="50" r="48" class="color"></circle> &lt;!&ndash; color &ndash;&gt;-->
        <!--<circle cx="50" cy="50" r="26" class="background"></circle> &lt;!&ndash; background &ndash;&gt;-->
        <!--</g>-->
        <g>
          <path
            d="M10.33 20.22A9.92 9.92 0 1 0 .38 10.3a9.9 9.9 0 0 0 9.95 9.92z"
            class="color stroke"
            stroke-miterlimit="10"
            stroke-width=".75"
          />
          <path
            class="background"
            d="M10.29 15.26a5 5 0 1 0-4.95-5 5 5 0 0 0 4.95 5z"
          />
        </g>
      </svg>
    </template>

    <template v-else-if="name === 'circle-basic-mode'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20.59 20.59"
        class="inside-icon circle-active-basic-development"
      >
        <!-- Basic Mode -->
        <!--<g>-->
        <!--<circle cx="50" cy="50" r="50" class="color"></circle> &lt;!&ndash; border &ndash;&gt;-->
        <!--<circle cx="50" cy="50" r="42" class="background"></circle> &lt;!&ndash; background &ndash;&gt;-->
        <!--<circle cx="50" cy="50" r="20" class="color"></circle> &lt;!&ndash; middle &ndash;&gt;-->
        <!--</g>-->
        <g>
          <path
            d="M10.26 20.22A9.92 9.92 0 1 0 .38 10.3a10 10 0 0 0 9.88 9.92z"
            class="background stroke"
            stroke-miterlimit="10"
            stroke-width=".75"
          />
          <path
            d="M10.26 15.26a5 5 0 1 0-5-5 5 5 0 0 0 5 5z"
            class="color"
          />
        </g>
      </svg>
    </template>

    <template v-else-if="name === 'circle-development-mode'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20.59 20.59"
        class="inside-icon circle-active-basic-development"
      >
        <!-- Development Mode -->
        <!--<g transform="translate(50, 50) rotate(0) translate(0, 0)">-->
        <!--<g-->
        <!--transform="rotate(270.000000) translate(-25.000000, -165.000000) translate(14.000000, 154.000000)">-->
        <!--<circle cx="11" cy="11" r="50" class="color"></circle> &lt;!&ndash; border &ndash;&gt;-->
        <!--<circle cx="11" cy="11" r="40" class="background"></circle> &lt;!&ndash; background &ndash;&gt;-->
        <!--<path-->
        <!--d="M15.6,14.8L16,8.6c0-0.5-0.3-0.9-0.8-0.9c-0.5,0-0.9,0.3-0.9,0.8L14,12.7L7.5,6.3     C7.3,6,7,5.9,6.7,6C6.3,6.1,6.1,6.3,6,6.7s0,0.6,0.2,0.9l6.5,6.5l-4.3,0.3c-0.5,0.1-0.8,0.5-0.8,0.9c0,0.5,0.5,0.8,0.9,0.8     l6.2-0.4C15.2,15.5,15.6,15.2,15.6,14.8z"-->
        <!--transform="scale(4) translate(-8,-8)"-->
        <!--class="color"></path> &lt;!&ndash; arrow &ndash;&gt;-->
        <!--</g>-->
        <!--</g>-->
        <g>
          <path
            class="background stroke"
            d="M17.32 17.3a9.92 9.92 0 1 0-14 0 9.9 9.9 0 0 0 14 0z"
            stroke-miterlimit="10"
            stroke-width=".75"
            fill-rule="evenodd"
          />
          <path
            class="color"
            d="M14.36 4.94l-6.84.22a1.43 1.43 0 0 0-.85.38 1.28 1.28 0 0 0 0 1.84 1.21 1.21 0 0 0
                         .92.34l3.57-.14-1.72 1.74s0 .08-.09.12-.09.05-.13.09l-4 4A1.27 1.27 0 0 0 7 15.31l4-4a1
                          1 0 0 0 .09-.13 1 1 0 0 0 .13-.09L13 9.36 12.85 13a1.19 1.19 0 0 0 .34.92 1.34 1.34 0
                           0 0 1.84.06 1.65 1.65 0 0 0 .38-.89l.22-6.84a1.2 1.2 0 0 0-1.27-1.27z"
          />
        </g>
      </svg>
    </template>

    <template v-else-if="name === 'imp-ball'">
      <!---->
      <!-- IMP_2020_C20_Original_Icon_Ball_008.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 126.59 126.59"
        class="icon-v3"
      >
        <circle
          class="svg-background-circle"
          cx="63.29"
          cy="63.29"
          r="63.29"
          transform="translate(-26.22 63.29) rotate(-45)"
        />
        <circle
          fill="#fff"
          cx="42.53"
          cy="72.43"
          r="24.05"
        />
      </svg>
    </template>
    <template v-else-if="name === 'imp-calendar'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Calendar_007.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M119.67,96.07c2.7,0,4.36-2.25,4.36-5.82s-1.66-5.65-4.36-5.65-4.35,2.07-4.35,5.65S117,96.07,119.67,96.07Z"
          />
          <path
            fill="#fff"
            d="M203,67.13V77.84a9.43,9.43,0,0,1-1.35,4.77,12.31,12.31,0,0,1-10.75,5.84,12.08,12.08,0,0,1-11.34-7,9.32,9.32,0,0,1-.76-3.6V66.28H104.86V77.84a9.32,9.32,0,0,1-.76,3.6,12.08,12.08,0,0,1-11.34,7A12.33,12.33,0,0,1,82,82.61a9.53,9.53,0,0,1-1.35-4.77V67.13C65.45,70.31,54.08,82.3,54.08,96.6v93.23c0,16.72,15.51,30.32,34.58,30.32H195c19.07,0,34.58-13.6,34.58-30.32V96.6C229.55,82.3,218.18,70.31,203,67.13ZM152.38,84.85V80.48h17.85v4.37h-5.95v15.34h-5.95V84.85ZM151,82.72l-3.11,3.34a5.86,5.86,0,0,0-3.74-1.46c-2.63,0-4.91,2.13-4.91,5.7s1.94,5.77,4.84,5.77a6,6,0,0,0,4.09-1.7l3.11,3.28a10.11,10.11,0,0,1-7.4,2.91c-5.75,0-10.73-3.34-10.73-10.07a9.8,9.8,0,0,1,5.39-9A12,12,0,0,1,144,80.11a11.28,11.28,0,0,1,7,2.61Zm-31.33-2.61a11.79,11.79,0,0,1,5.67,1.33c3,1.6,4.78,4.6,4.78,8.81,0,6.49-4.22,10.31-10.45,10.31s-10.44-3.82-10.44-10.31c0-4.23,1.8-7.22,4.76-8.81A11.88,11.88,0,0,1,119.67,80.11Zm92.59,109.72c0,8.36-7.75,15.16-17.29,15.16H88.66c-9.54,0-17.29-6.8-17.29-15.16V110.62H212.26Z"
          />
          <path
            fill="#fff"
            d="M92.76,84.28a7.68,7.68,0,0,0,6.1-2.84,5.89,5.89,0,0,0,1.25-3.6v-22c0-3.56-3.29-6.44-7.35-6.44s-7.34,2.88-7.34,6.44v22a5.89,5.89,0,0,0,1.34,3.7A7.69,7.69,0,0,0,92.76,84.28Z"
          />
          <path
            fill="#fff"
            d="M190.87,84.28a7.69,7.69,0,0,0,6-2.74,5.9,5.9,0,0,0,1.35-3.7v-22c0-3.56-3.29-6.44-7.35-6.44s-7.35,2.88-7.35,6.44v22a5.89,5.89,0,0,0,1.25,3.6A7.68,7.68,0,0,0,190.87,84.28Z"
          />
          <rect
            id="hide_month"
            fill="#fff"
            x="108.23"
            y="78.09"
            width="63.59"
            height="23.81"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'close-cancel-cross'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Cancel_001.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M172.63,142l41.76-41.77a21.43,21.43,0,0,0,0-30.23l-.51-.51a21.43,21.43,0,0,0-30.23,0l-41.77,41.77L100.11,69.51a21.45,21.45,0,0,0-30.24,0l-.51.52a21.44,21.44,0,0,0,0,30.23L111.14,142,69.36,183.79a21.44,21.44,0,0,0,0,30.23l.51.52a21.46,21.46,0,0,0,30.24,0l41.77-41.78,41.77,41.78a21.44,21.44,0,0,0,30.23,0l.51-.52a21.43,21.43,0,0,0,0-30.23Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'imp-doing-action'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Doing-Action_001.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M141.82,177.14a21.74,21.74,0,1,0,21.74,21.74A21.75,21.75,0,0,0,141.82,177.14Z"
          />
          <path
            fill="#fff"
            d="M160,146.82c2.06-41.46,1.26-23.67,3.61-80.34,0-28.54-43.47-28.47-43.48,0,2.36,56.68,1.56,38.88,3.61,80.34C124.67,169.79,159,169.83,160,146.82Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'imp-skip'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Skip_002.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M94.5,213.76a86.56,86.56,0,0,1,37-158.39l0-21.65,60.41,34.74L131.56,103.4l0-19.75a58.57,58.57,0,1,0,64,34.2l25.62-11.18a86,86,0,0,1,5,54.24,85.86,85.86,0,0,1-28.41,46.47A86.54,86.54,0,0,1,94.5,213.76Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'imp-done'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Check_Done_001.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M233,75.58l-.48-.47a19.83,19.83,0,0,0-28,0L113.3,166.32l-33.9-33.9a19.83,19.83,0,0,0-28,0l-.47.48a19.83,19.83,0,0,0,0,28l48.36,48.36a19.85,19.85,0,0,0,28,0L233,103.54A19.85,19.85,0,0,0,233,75.58Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'imp-individual-empowerment'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Individual-Empowerment_001.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M120.2,205.57c-3.84,0-7.14-1.54-7.92-4.93l-10.73-46.5-7,11a8.47,8.47,0,0,1-7,3.81H48.49c-4.45,0-8.08-3.27-8.08-7.3s3.63-7.33,8.08-7.33H82.93l15.35-23.5a8.36,8.36,0,0,1,8.28-3.49,7.76,7.76,0,0,1,6.58,5.73l6.49,28.41L134.44,82.3c.69-9,4-15.27,7.95-15.27h0c3.92,0,7.27,6.36,7.93,15.42l15.8,88.23L171.23,159a8.11,8.11,0,0,1,7.51-4.66H239c4.46,0,8.08,3.27,8.08,7.33S243.49,169,239,169H184.22l-13.67,31.29a8.17,8.17,0,0,1-8.34,4.58,7.82,7.82,0,0,1-7.13-6l-12.89-68.23-14.05,69.84c-.69,3.45-4,5.13-7.84,5.13Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'imp-pencil'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Pen_Journal_003.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M68.33,214.19,84.66,173l29,29L72.5,218.35C68.7,219.86,66.83,218,68.33,214.19Zm160-127.37a7.42,7.42,0,0,0,0-10.5L209.82,57.78a7.41,7.41,0,0,0-10.49,0l-10,10,29,29Zm-17,17-29-29-92,91.52,29,29Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'tly-diamond'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 56.69 56.69"
        class="icon-v3"
      >
        <circle
          class="svg-background-circle"
          cx="28.35"
          cy="28.35"
          r="28.35"
        />
        <path
          fill="#fff"
          d="M21,31.33l20.62,11a.47.47,0,0,0,.63-.63L31.35,21a.47.47,0,0,0-.74-.11L20.93,30.6A.45.45,0,0,0,21,31.33Z"
        />
        <path
          fill="#fff"
          d="M11.18,26.92a1.21,1.21,0,0,1,.41-.91c.76-.66,1.55-1.37,2.38-2.12a.51.51,0,0,1,.83.2l2.3,6.35a.92.92,0,0,1-.2,1l-4.25,5a.58.58,0,0,0,0,.81l0,0a.56.56,0,0,0,.81,0l5-4.26a.57.57,0,0,1,.73-.13L39.52,43.67a.47.47,0,0,1-.3.87l-26.75-5.6a1.89,1.89,0,0,1-1.3-1.55Z"
        />
        <path
          fill="#fff"
          d="M19,19c-.71.72-1.42,1.42-2.12,2.09a1.31,1.31,0,0,0-.32,1.39l2.21,6.11a.46.46,0,0,0,.76.17l9.26-9.26a.46.46,0,0,0-.17-.76l-6.11-2.22a1.31,1.31,0,0,0-1.39.33C20.46,17.59,19.75,18.31,19,19Z"
        />
        <path
          fill="#fff"
          d="M26.92,11.18a1.21,1.21,0,0,0-.91.41c-.66.76-1.37,1.55-2.12,2.38a.51.51,0,0,0,.2.83l6.35,2.3a.92.92,0,0,0,1-.2l5-4.25a.58.58,0,0,1,.81,0l0,0a.56.56,0,0,1,0,.81l-4.26,5a.57.57,0,0,0-.13.73L43.67,39.52a.47.47,0,0,0,.87-.3l-5.6-26.75a1.89,1.89,0,0,0-1.55-1.3Z"
        />
      </svg>
    </template>
    <template v-else-if="name === 'tly-shovel'">
      <!---->
      <!-- TLY_2021_System_Design_Icons_Shovel_001 -->
      <!---->
      <!--      <svg-->
      <!--        xmlns="http://www.w3.org/2000/svg"-->
      <!--        xmlns:xlink="http://www.w3.org/1999/xlink"-->
      <!--        viewBox="0 0 283.53 283.53"-->
      <!--        class="icon-v3"-->
      <!--      >-->
      <!--        <defs>-->
      <!--          <clipPath id="shovel-001-clip-path">-->
      <!--            <rect-->
      <!--              fill="none"-->
      <!--              x="54.77"-->
      <!--              y="46.53"-->
      <!--              width="181.76"-->
      <!--              height="179.72"-->
      <!--            />-->
      <!--          </clipPath>-->
      <!--        </defs>-->
      <!--        <g id="background-circle">-->
      <!--          <circle-->
      <!--            class="svg-background-circle"-->
      <!--            cx="141.76"-->
      <!--            cy="141.76"-->
      <!--            r="141.73"-->
      <!--          />-->
      <!--        </g>-->
      <!--        <g-->
      <!--          id="symbol"-->
      <!--          style="clip-path: url(#shovel-001-clip-path);"-->
      <!--        >-->
      <!--          <path-->
      <!--            fill="#fff"-->
      <!--            d="M202.67,89.32l-76.31,75.44-18.82,18.61a6.71,6.71,0,0,1-9.41,0,6.52,6.52,0,0,1,0-9.3L117,155.46,193.25,80a44.79,44.79,0,0,1-5.72-19.38,6.46,6.46,0,0,1,1.95-4.8l2.75-2.72,4.71-4.65a6.71,6.71,0,0,1,9.41,0l28.23,27.91a6.53,6.53,0,0,1,0,9.31l-4.71,4.65-2.75,2.72A6.53,6.53,0,0,1,222.26,95a46,46,0,0,1-19.59-5.65m8.38-17.6-14.11-14-2.75,2.72c.46,10.1,5.92,18.32,7.5,20.5,2.22,1.57,10.53,7,20.73,7.41l2.75-2.71Z"-->
      <!--          />-->
      <!--          <path-->
      <!--            fill="#fff"-->
      <!--            d="M101.36,140.05l13.24,13.08L95.78,171.74a9.79,9.79,0,0,0,0,14,10.06,10.06,0,0,0,14.11,0l18.82-18.61,13.23,13.09a9.39,9.39,0,0,1,0,13.4l-22.42,22.18c-13,12.82-37.15,11.19-50.87,9a14.85,14.85,0,0,1-12.31-12.17c-2.25-13.56-3.9-37.47,9.07-50.3L87.8,140.08a9.67,9.67,0,0,1,13.56,0"-->
      <!--          />-->
      <!--        </g>-->
      <!--      </svg>-->
      <!---->
      <!-- TLY_2021_System_Design_Icons_Shovel_002 -->
      <!---->
      <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M148.89,159.14l-2.21-2.21-11.19,11.2a14.85,14.85,0,0,1-21-21L125.68,136l-2.21-2.21-25-10L61.67,160.47c-10.28,10.28-13.79,24.14-10.15,40.09a69.8,69.8,0,0,0,8.24,20.3l.78,1.22,1.22.78a70.14,70.14,0,0,0,20.3,8.25,53.74,53.74,0,0,0,11.83,1.39A38.61,38.61,0,0,0,122.15,221l36.74-36.74Z"
          />
          <path
            fill="#fff"
            d="M203.51,68.54l-83.82,83.79a7.5,7.5,0,0,0,10.6,10.6l83.79-83.82c4.29,4.29,15.23,14,21.17,8,3.57-3.58,3.59-7.55-2.21-13.35l-12.1-12.1-12.1-12.09c-5.8-5.81-9.77-5.79-13.34-2.22C189.56,53.31,199.22,64.25,203.51,68.54Z"
          />
        </g>
      </svg> -->
      <!---->
      <!-- TLY_2023_System_Design_Icons_Shovel_Blue_003 -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="m148.58,157.85l-2.07-2.07c-6.32,6.33-10.44,10.44-10.49,10.49-5.42,5.42-14.25,5.42-19.67,0-5.42-5.42-5.42-14.25,0-19.67.05-.05,4.16-4.16,10.48-10.48l-2.07-2.07-23.47-9.39-34.43,34.43c-9.63,9.63-12.91,22.61-9.51,37.56,2.43,10.68,7.51,18.69,7.73,19.02l.73,1.14,1.14.73c.33.21,8.33,5.29,19.02,7.73,3.8.87,7.53,1.31,11.08,1.31,10.39,0,19.3-3.64,26.48-10.82l34.43-34.43-9.37-23.49Z"
          />
          <path
            fill="#fff"
            d="m199.75,72.97s-78.32,78.28-78.54,78.5c-2.74,2.74-2.74,7.19,0,9.93,2.74,2.74,7.19,2.74,9.93,0,.22-.22,78.5-78.54,78.5-78.54,4.02,4.02,14.27,13.08,19.84,7.51,3.35-3.35,3.36-7.07-2.08-12.51l-11.33-11.33-11.33-11.33c-5.44-5.44-9.16-5.42-12.51-2.08-5.56,5.56,3.49,15.82,7.51,19.84Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'tly-team'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g transform="scale(11) translate(-175.5 -41)">
          <path
            fill="#fff"
            d="M186.45,59.37a12.5,12.5,0,0,0,4.06,1.08,1.14,1.14,0,0,0,.09-.19c.6-1.36.16-2.54-1-3.63a4.76,4.76,0,0,1-1.88-.33,4.63,4.63,0,0,1-1.5-1.18c-1.62-.16-2.8.3-3.4,1.66a1.45,1.45,0,0,0-.08.21A13.69,13.69,0,0,0,186.45,59.37Z"
          />
          <path
            fill="#fff"
            d="M189.89,51.34a2.36,2.36,0,1,0,1.16,3.1A2.35,2.35,0,0,0,189.89,51.34Z"
          />
          <path
            fill="#fff"
            d="M194.59,51.5a2.34,2.34,0,0,0-2.92.93,3,3,0,0,1-.07,2.26l0,.06a2.27,2.27,0,0,0,1.1,1.06,2.36,2.36,0,0,0,1.92-4.31Z"
          />
          <path
            fill="#fff"
            d="M186.17,54.52a2.93,2.93,0,0,1-.15-1.25,4.63,4.63,0,0,1-1.53-.33A4.51,4.51,0,0,1,183,51.76c-1.61-.15-2.79.31-3.39,1.67a1.65,1.65,0,0,0-.09.21,13.45,13.45,0,0,0,3.2,2.12A3.69,3.69,0,0,1,186.17,54.52Z"
          />
          <path
            fill="#fff"
            d="M184.78,52.3a2.28,2.28,0,0,0,1.43.13s0-.08,0-.13A3,3,0,0,1,188,50.72a2.32,2.32,0,0,0-4.37-1.52A2.34,2.34,0,0,0,184.78,52.3Z"
          />
          <path
            fill="#fff"
            d="M194.27,56.79a4.59,4.59,0,0,1-1.88-.33,4.73,4.73,0,0,1-1.33-1,3.09,3.09,0,0,1-1.05.74,3.69,3.69,0,0,1,1.4,3.41,12.4,12.4,0,0,0,3.81,1c0-.06.05-.11.09-.2C195.9,59.07,195.47,57.88,194.27,56.79Z"
          />
        </g>
      </svg>
    </template>

    <template v-else-if="name === 'imp-journal' || name === 'tly-journal'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Pen_Journal_003.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            style="fill: #fff; stroke: #fff; stroke-miterlimit: 10;"
            d="M68.33,214.19,84.66,173l29,29L72.5,218.35C68.7,219.86,66.83,218,68.33,214.19Zm160-127.37a7.42,7.42,0,0,0,0-10.5L209.82,57.78a7.41,7.41,0,0,0-10.49,0l-10,10,29,29Zm-17,17-29-29-92,91.52,29,29Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'imp-personal-development-area' || name === 'tly-personal-development-area'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Process-Progress_002.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M194.84,98.84a42.85,42.85,0,0,0-30.32,12.33l-22.69,20.08,0,0-12.17,10.72.08.08-21.64,19.2a27.17,27.17,0,1,1-19.24-46.3,27.44,27.44,0,0,1,19.6,8.27l9.12,8.07,12.12-10.72-10.16-9a43.14,43.14,0,1,0-.36,61.37l22.69-20.12,0,0L154,142.05l0,0,21.64-19.15a27.16,27.16,0,1,1,19.24,46.26,27.57,27.57,0,0,1-19.6-8.23l-9.11-8.07L154,163.58l10.16,9a43.28,43.28,0,1,0,30.68-73.73Z"
          />
          <path
            fill="#fff"
            d="M194.84,94.59a48.85,48.85,0,0,0-25.72,7.47c-4,2.57-7.35,5.88-10.88,9l-19.42,17.19h6l0,0a4.35,4.35,0,0,0-6,0l-12.16,10.72a4.29,4.29,0,0,0,0,6l.08.08v-6l-14.1,12.5c-6.34,5.62-12.34,12.27-21.27,13.22-12.77,1.36-24.7-8.39-25.45-21.32a23.13,23.13,0,0,1,22.24-24.23c11.26-.29,18.64,8.2,26.41,15.08a4.37,4.37,0,0,0,6,0l12.13-10.72a4.29,4.29,0,0,0,0-6c-4.17-3.69-8.25-7.49-12.46-11.12A47.71,47.71,0,0,0,44.39,125a47.58,47.58,0,0,0,14.16,53.57c16.4,13.36,40.62,14.53,57.93,2.07,10.09-7.26,19.06-16.63,28.36-24.87h-6l0,0a4.35,4.35,0,0,0,6,0L157,145.06a4.3,4.3,0,0,0,0-6l0,0v6l14.1-12.47c6.34-5.62,12.35-12.3,21.27-13.25,12.77-1.36,24.7,8.39,25.45,21.32a23.14,23.14,0,0,1-22.24,24.23c-11.26.28-18.64-8.16-26.41-15a4.37,4.37,0,0,0-6,0L151,160.57a4.3,4.3,0,0,0,0,6c7,6.19,13.86,13.56,22.29,17.75a48.52,48.52,0,0,0,42.67.16c25.4-12.34,34.41-45.89,18.24-69.16a48.51,48.51,0,0,0-39.35-20.75c-5.48-.08-5.48,8.42,0,8.51,24.06.38,43.41,22.53,38.35,46.64C228,174.28,200.25,188.16,177.57,177c-7.75-3.82-14.12-10.73-20.56-16.43v6l12.12-10.77h-6c8.57,7.59,16.56,16.18,28.63,17.38a31.33,31.33,0,0,0,17-59.29c-13.24-6.44-26.8-2.49-37.28,6.91C164.69,126.93,157.8,133,151,139a4.3,4.3,0,0,0,0,6l0,0v-6l-12.13,10.76h6l0,0a4.35,4.35,0,0,0-6,0L118,168.27c-5.3,4.7-10.56,8.83-17.48,10.92a39.78,39.78,0,0,1-27.9-1.75c-15.78-7.23-25.31-24.61-22.36-41.82a39.19,39.19,0,0,1,65.14-22.17c3.74,3.39,7.52,6.74,11.3,10.09v-6l-12.13,10.73h6C112,120.65,104,112,91.91,110.83a31.33,31.33,0,0,0-17,59.28c13.21,6.43,26.81,2.53,37.28-6.87,6.82-6.11,13.7-12.15,20.55-18.22a4.3,4.3,0,0,0,0-6l-.08-.08v6l12.17-10.72h-6l0,0a4.37,4.37,0,0,0,6,0l17.66-15.63c2.46-2.18,4.85-4.47,7.35-6.6a39.4,39.4,0,0,1,25-8.93C200.3,103,200.32,94.49,194.84,94.59Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'language-v3'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Language_005.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M231.32,137.57a89.05,89.05,0,0,0-20.51-52.83c-2.21,1.05-4.48,2.07-6.76,3a159.9,159.9,0,0,1-16.81,5.95,111.22,111.22,0,0,1,11,43.86Z"
          />
          <path
            fill="#fff"
            d="M137.74,146.13H94.33a103.33,103.33,0,0,0,10.92,41.64,160.92,160.92,0,0,1,32.49-4.16Z"
          />
          <path
            fill="#fff"
            d="M132.29,224.39c1.74,1.72,3.57,3.43,5.45,5.12V192.18a152.87,152.87,0,0,0-28.25,3.42c.7,1.18,1.42,2.36,2.17,3.54A144.11,144.11,0,0,0,132.29,224.39Z"
          />
          <path
            fill="#fff"
            d="M179.61,80c1.27,2,2.45,4,3.59,6a150.69,150.69,0,0,0,17.55-6.11c1.33-.55,2.65-1.14,4-1.73A89.1,89.1,0,0,0,158.53,54,152.56,152.56,0,0,1,179.61,80Z"
          />
          <path
            fill="#fff"
            d="M189.71,146.13h-43.4v37.48a160.83,160.83,0,0,1,32.48,4.16A103.13,103.13,0,0,0,189.71,146.13Z"
          />
          <path
            fill="#fff"
            d="M151.76,59.31c-1.75-1.72-3.58-3.44-5.45-5.12V91.52a152.77,152.77,0,0,0,28.24-3.42c-.7-1.18-1.42-2.36-2.17-3.54A144.32,144.32,0,0,0,151.76,59.31Z"
          />
          <path
            fill="#fff"
            d="M146.31,229.51c1.87-1.68,3.7-3.4,5.45-5.12a144.32,144.32,0,0,0,20.62-25.25c.75-1.18,1.47-2.36,2.17-3.54a152.77,152.77,0,0,0-28.24-3.42Z"
          />
          <path
            fill="#fff"
            d="M178.79,95.93a160.83,160.83,0,0,1-32.48,4.16v37.48h43.4A103.13,103.13,0,0,0,178.79,95.93Z"
          />
          <path
            fill="#fff"
            d="M83.3,79.85A150,150,0,0,0,100.85,86q1.7-3,3.58-6a153.13,153.13,0,0,1,21.08-26A89.07,89.07,0,0,0,79.34,78.12C80.65,78.72,82,79.3,83.3,79.85Z"
          />
          <path
            fill="#fff"
            d="M104.43,203.74q-1.89-3-3.58-6a151.85,151.85,0,0,0-17.55,6.1c-1.33.56-2.65,1.14-4,1.74a89.14,89.14,0,0,0,46.18,24.14A152.74,152.74,0,0,1,104.43,203.74Z"
          />
          <path
            fill="#fff"
            d="M200.75,203.84a152.51,152.51,0,0,0-17.55-6.1c-1.14,2-2.33,4-3.59,6a152.17,152.17,0,0,1-21.08,26,89.1,89.1,0,0,0,46.18-24.14C203.4,205,202.08,204.4,200.75,203.84Z"
          />
          <path
            fill="#fff"
            d="M198.29,146.13a111.22,111.22,0,0,1-11,43.86,159.9,159.9,0,0,1,16.81,5.95c2.28.95,4.54,2,6.76,3a89.05,89.05,0,0,0,20.51-52.83Z"
          />
          <path
            fill="#fff"
            d="M80,195.94A159.27,159.27,0,0,1,96.81,190a111.06,111.06,0,0,1-11.06-43.86h-33A89,89,0,0,0,73.24,199C75.46,197.9,77.73,196.89,80,195.94Z"
          />
          <path
            fill="#fff"
            d="M137.74,54.19c-1.88,1.69-3.71,3.41-5.45,5.12a144.47,144.47,0,0,0-20.63,25.25c-.75,1.18-1.47,2.36-2.17,3.54a152.87,152.87,0,0,0,28.25,3.42Z"
          />
          <path
            fill="#fff"
            d="M137.74,100.09a160.92,160.92,0,0,1-32.49-4.16,103.33,103.33,0,0,0-10.92,41.64h43.41Z"
          />
          <path
            fill="#fff"
            d="M85.75,137.57A111.06,111.06,0,0,1,96.81,93.71,159.27,159.27,0,0,1,80,87.76c-2.26-1-4.53-2-6.75-3a89,89,0,0,0-20.51,52.83Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'imp-percent'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Percent_005.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M102.92,58.06a36.24,36.24,0,0,1,14.3,2.87A38,38,0,0,1,129,68.73a37.57,37.57,0,0,1,8,11.59,35.47,35.47,0,0,1,0,28.46,37.41,37.41,0,0,1-8,11.58,37.85,37.85,0,0,1-11.78,7.81,37.18,37.18,0,0,1-28.6,0,37.33,37.33,0,0,1-19.79-19.39,35.57,35.57,0,0,1,0-28.46A37.24,37.24,0,0,1,88.62,60.93,36.24,36.24,0,0,1,102.92,58.06Zm86-4.34,13,8.92L94.23,228.25l-13-8.92Zm-86,59.47a17.87,17.87,0,0,0,13.27-5.34,18.23,18.23,0,0,0,5.26-13.3,18.22,18.22,0,0,0-5.26-13.3,17.88,17.88,0,0,0-13.27-5.35A18.14,18.14,0,0,0,84.16,94.55a18.13,18.13,0,0,0,18.76,18.64Zm78.69,37.52a36.06,36.06,0,0,1,14.3,2.87A37.17,37.17,0,0,1,215.69,173a35.44,35.44,0,0,1,0,28.45,37.17,37.17,0,0,1-19.78,19.39,37.06,37.06,0,0,1-28.6,0,37.59,37.59,0,0,1-11.78-7.8,37.12,37.12,0,0,1-8-11.59,35.44,35.44,0,0,1,0-28.45,37.12,37.12,0,0,1,8-11.59,37.59,37.59,0,0,1,11.78-7.8A36.06,36.06,0,0,1,181.61,150.71Zm0,55.13a17.88,17.88,0,0,0,13.27-5.35,19.44,19.44,0,0,0,0-26.6,17.87,17.87,0,0,0-13.27-5.34,18.65,18.65,0,1,0,0,37.29Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'talent-manual'">
      <!---->
      <!-- (old) IMP_2020_C20_System_Design_Icons_Talent-Manual_003.svg -->
      <!-- (new 2023-07-06) TLY_2023_System_Design_Talent-Manual_001.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.46 283.46"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.73"
            cy="141.73"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <circle
            style="fill: #fff;"
            cx="141.71"
            cy="92.17"
            r="39.24"
          />
          <path
            style="fill: #fff;"
            d="m226.71,138.55l-79.95,19.29v67.3l81.43-26.09c1.41-.34,2.41-1.6,2.41-3.06v-54.39c0-2.04-1.9-3.54-3.88-3.06Z"
          />
          <path
            style="fill: #fff;"
            d="m52.87,141.64v54.34c0,1.47,1,2.74,2.43,3.08l81.41,26.08v-67.3l-79.92-19.28c-2-.48-3.92,1.03-3.92,3.08Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'imp-understanding-more'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Understanding-more_003.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M172.49,164.36,188.59,180c-3.14,4.27-19,24.69-35.76,26.09.09-1.62.1-4.39.13-9.12,0-6.35,0-15.08,0-24.57,0-19-.11-41.07-.15-53.43l0-4.88c13.7-4.83,22.85-17.39,22.85-31.52,0-18.59-15.57-33.72-34.68-33.72S106.27,64,106.27,82.54c0,13.84,8.92,26.34,22.27,31.36l.09,91.81c-20-2-32.69-19.46-35.26-23.31l17.17-16.63L48,156.53l9.47,60.63L74,201.24c2.3,1.91,4.68,4.17,7.19,6.54C93,219,109.23,234.36,139.47,234.36h0c37.17-.94,51.54-16.91,62-28.57a88.59,88.59,0,0,1,6.59-6.85l18,17.49,9-61.2ZM141,66.35c8.94,0,16.2,7,16.2,15.66A16.19,16.19,0,1,1,141,66.35Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'imp-video'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Video_002.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M219.66,129.83,111.12,67.16C99.74,60.58,90.43,66,90.43,79.09V204.43c0,13.14,9.31,18.51,20.69,11.94l108.54-62.66C231,147.14,231,136.4,219.66,129.83Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'imp-role-switcher'">
      <!---->
      <!-- IMP_2020_C20_System_Design_Icons_Role_001.svg -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M99.76,118.32a46.12,46.12,0,1,1,9.85,14.61A45.74,45.74,0,0,1,99.76,118.32Zm110,82.43A78.69,78.69,0,0,0,200.14,178a75.58,75.58,0,0,0-8.55-11.25,72.69,72.69,0,0,0-10.37-9.27,68.19,68.19,0,0,0-11.88-7c-.8-.37-1.6-.72-2.41-1.05-1.13.58-2.28,1.11-3.46,1.61a55.11,55.11,0,0,1-42.73,0c-1.17-.5-2.33-1-3.46-1.61-.81.33-1.61.68-2.41,1.05a68.56,68.56,0,0,0-11.88,7,72.08,72.08,0,0,0-10.36,9.27A74.91,74.91,0,0,0,84.08,178a78,78,0,0,0-6.46,12.89,80.71,80.71,0,0,0-3.13,9.9,3.75,3.75,0,0,0,1.1,3.68,103.41,103.41,0,0,0,25.69,16.86,97.68,97.68,0,0,0,81.65,0,103.23,103.23,0,0,0,25.69-16.86A3.75,3.75,0,0,0,209.72,200.75Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'adobe-target'">
      <!---->
      <!-- AdobeStock_302041526_Target_Icon.svg, adapted -->
      <!---->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2257.72 2258.5"
      >
        <!--        <g id="background-circle">-->
        <!--          <circle-->
        <!--            class="svg-background-circle"-->
        <!--            cx="1128.86"-->
        <!--            cy="1128.86"-->
        <!--            r="1128.86"-->
        <!--          />-->
        <!--        </g>-->
        <!--        scale centered: (1 - scale) * currentPosition -->
        <!--        If the center is (10, 20) and you are scaling by 3 then translate by (1 - 3)*10, (1 - 3)*20 = (-20, -40): transform="translate(-20, -40) scale(3)" -->
        <!--        center is 1128.86. For 0.8 scale: (1 - 0.8) * 1128.86 = 225.772 --- transform="translate(225.772, 225.772) scale(0.8)" -->
        <!--        center is 1128.86. For 0.5 scale: (1 - 0.5) * 1128.86 = 564.43 --- transform="translate(564.43, 564.43) scale(0.5)" -->
        <g
          id="symbol"
          transform="translate(225.772, 225.772) scale(0.8)"
        >
          <path
            fill="#fff"
            d="M1907.85,694.4l-105.33,24.13-45.78,45.77a834,834,0,0,1,128.87,446.64c0,462.84-375.21,838-838,838s-838-375.21-838-838,375.21-838,838.05-838a834,834,0,0,1,445.68,128.27l46-46,24.13-105.33a250.53,250.53,0,0,1,14-42.8c-155.42-91.29-336.44-143.69-529.72-143.69C469,163.38,0,632.39,0,1210.94S469,2258.5,1047.56,2258.5s1047.56-469,1047.56-1047.56c0-193.65-52.61-375-144.22-530.61A250.34,250.34,0,0,1,1907.85,694.4Z"
          />
          <path
            fill="#fff"
            d="M1047.56,791.91a418.67,418.67,0,0,1,133.27,21.65l159.11-159.11a625.79,625.79,0,0,0-292.38-72.05C700.43,582.4,419,863.81,419,1210.94s281.41,628.53,628.54,628.53,628.54-281.4,628.54-628.53a625.75,625.75,0,0,0-72.59-293.4l-159,159a418.45,418.45,0,0,1,22,134.45c0,231.42-187.6,419-419,419s-419-187.6-419-419S816.14,791.91,1047.56,791.91Z"
          />
          <path
            fill="#fff"
            d="M1047.56,1001.42c-115.71,0-209.51,93.81-209.51,209.52s93.8,209.51,209.51,209.51,209.51-93.8,209.51-209.51a208.78,208.78,0,0,0-15.64-79.47l508.32-508.33,134.71-30.85c26-6,48.93-18.41,66.85-36.34l306.41-306.4-286.29,36.74L2008.17,0l-306.4,306.41c-17.93,17.92-30.38,40.83-36.34,66.85L1634.58,508l-508.66,508.66A209,209,0,0,0,1047.56,1001.42Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'jgo-home-v3'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M223.38,131H212.52v72.83a6.25,6.25,0,0,1-6.25,6.25H168.79a6.25,6.25,0,0,1-6.25-6.25V160.26A6.24,6.24,0,0,0,156.3,154H127.23a6.24,6.24,0,0,0-6.24,6.25v43.59a6.25,6.25,0,0,1-6.25,6.25H77.26A6.25,6.25,0,0,1,71,203.85V131H60.15a6.25,6.25,0,0,1-3.87-11.15L71,108.21,137.89,55.3a6.24,6.24,0,0,1,7.75,0l66.88,52.91,14.73,11.66A6.25,6.25,0,0,1,223.38,131Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'jgo-model-v3'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M185.78,103.23a61.06,61.06,0,0,1,17.07,38.38l35.08-1.2a2.66,2.66,0,0,0,2.57-2.89A99.1,99.1,0,0,0,214,78a2.68,2.68,0,0,0-3.87,0Z"
          />
          <path
            fill="#fff"
            d="M207.2,71.52a99,99,0,0,0-60.39-24.41,2.67,2.67,0,0,0-2.8,2.67v35.1a61,61,0,0,1,39,15.75l24.4-25.25A2.67,2.67,0,0,0,207.2,71.52Z"
          />
          <path
            fill="#fff"
            d="M137.38,47.11A99.11,99.11,0,0,0,77,71.51a2.68,2.68,0,0,0-.16,3.87l24.39,25.23a61.1,61.1,0,0,1,38.95-15.73V49.78A2.67,2.67,0,0,0,137.38,47.11Z"
          />
          <path
            fill="#fff"
            d="M81.33,141.65a61.07,61.07,0,0,1,17.08-38.41L74,78a2.67,2.67,0,0,0-3.87,0,99.13,99.13,0,0,0-26.47,59.53,2.67,2.67,0,0,0,2.57,2.89Z"
          />
          <path
            fill="#fff"
            d="M185.79,103.22l-6.28,6.51a2.67,2.67,0,0,0-.09,3.61,49.07,49.07,0,0,1,4.69,6.42.61.61,0,0,0,.84.2,5.65,5.65,0,0,1,2.67-.84,6.09,6.09,0,0,1,3,11.64,4.93,4.93,0,0,1-.73.27.6.6,0,0,0-.4.76,51.22,51.22,0,0,1,1.64,7.78,2.66,2.66,0,0,0,2.74,2.35l9-.31A61.1,61.1,0,0,0,185.79,103.22Z"
          />
          <path
            fill="#fff"
            d="M144,84.88v9a2.66,2.66,0,0,0,2.45,2.65,49.35,49.35,0,0,1,7.83,1.37.6.6,0,0,0,.74-.44A5.77,5.77,0,0,1,156.37,95a6.09,6.09,0,0,1,10.23,6.3,5,5,0,0,1-.34.7.61.61,0,0,0,.24.83,49.4,49.4,0,0,1,6.58,4.46,2.68,2.68,0,0,0,3.61-.21l6.28-6.51A61.08,61.08,0,0,0,144,84.88Z"
          />
          <path
            fill="#fff"
            d="M140.17,84.87v9a2.66,2.66,0,0,1-2.45,2.65,49.35,49.35,0,0,0-7.83,1.37.62.62,0,0,1-.75-.44A5.63,5.63,0,0,0,127.8,95a6.19,6.19,0,0,0-7-1.62,6.11,6.11,0,0,0-3.27,7.91,5,5,0,0,0,.33.7.6.6,0,0,1-.23.83,50.16,50.16,0,0,0-6.59,4.46,2.68,2.68,0,0,1-3.6-.21l-6.28-6.51A61.08,61.08,0,0,1,140.17,84.87Z"
          />
          <path
            fill="#fff"
            d="M81.32,141.63l9,.32a2.67,2.67,0,0,0,2.74-2.36,48.55,48.55,0,0,1,1.64-7.77.62.62,0,0,0-.42-.77,5.75,5.75,0,0,1-2.41-1.42,6.18,6.18,0,0,1-1.36-7,6.1,6.1,0,0,1,8-3,4.52,4.52,0,0,1,.69.36.6.6,0,0,0,.83-.21,49.75,49.75,0,0,1,4.69-6.42,2.67,2.67,0,0,0-.09-3.61l-6.28-6.51A61,61,0,0,0,81.32,141.63Z"
          />
          <circle
            fill="#fff"
            cx="96.07"
            cy="125.2"
            r="4.76"
          />
          <circle
            fill="#fff"
            cx="123.23"
            cy="99.04"
            r="4.76"
          />
          <circle
            fill="#fff"
            cx="160.94"
            cy="99.04"
            r="4.76"
          />
          <circle
            fill="#fff"
            cx="188.12"
            cy="125.18"
            r="4.76"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'jgo-partner-and-knowledge-v3'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.53 283.53"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            fill="#fff"
            d="M174.39,130.29V115a7.89,7.89,0,0,0-7.76-7.92H101.89V88.5A4.31,4.31,0,0,0,95,84.82L45.4,123a4.7,4.7,0,0,0,0,7.36L95,168.57a4.32,4.32,0,0,0,6.89-3.69V145.74a15.61,15.61,0,0,1,15.65-15.45Z"
          />
          <path
            fill="#fff"
            d="M238.68,153.72,189,115.53a4.44,4.44,0,0,0-7.07,3.68v18.92H117.54a7.66,7.66,0,0,0-7.81,7.61v23.33a7.93,7.93,0,0,0,7.81,7.92H181.9V195.6a4.44,4.44,0,0,0,7.06,3.68l49.73-38.19A4.7,4.7,0,0,0,238.68,153.72Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'circle-of-elements-avatar-face'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.46 283.46"
        class="icon-v3"
      >
        <!--        <g id="background-circle">-->
        <!--          <circle-->
        <!--            class="svg-background-circle"-->
        <!--            cx="141.76"-->
        <!--            cy="141.76"-->
        <!--            r="141.73"-->
        <!--          />-->
        <!--        </g>-->
        <!--        <g id="Hilfskreis">-->
        <!--          <circle-->
        <!--            style="fill:none;stroke:#fff;"-->
        <!--            cx="141.86"-->
        <!--            cy="141.53"-->
        <!--            r="114.8"-->
        <!--          />-->
        <!--        </g>-->
        <g id="Symbol">
          <path
            class="circle-of-elements-avatar-face"
            d="M228.17,556a142.07,142.07,0,1,1,55.17,11.15A141.11,141.11,0,0,1,228.17,556Z"
            transform="translate(-141.61 -283.67)"
          />
          <ellipse
            class="circle-of-elements-avatar-face-bg"
            cx="90.3"
            cy="112.76"
            rx="19.43"
            ry="24.79"
          />
          <ellipse
            class="circle-of-elements-avatar-face-bg"
            cx="193.16"
            cy="112.76"
            rx="19.43"
            ry="24.79"
          />
          <path
            class="circle-of-elements-avatar-face-bg"
            d="M219.47,460.17a9.78,9.78,0,0,1,10.23-2.62c.25.09.5.18.75.29a134.56,134.56,0,0,0,105.78,0,9.74,9.74,0,0,1,12.83,12.71c-5.92,14.23-15,25.2-27,32.61C311,510,298,513.51,283.34,513.51s-27.65-3.48-38.75-10.35c-12-7.41-21.05-18.38-27-32.61A9.74,9.74,0,0,1,219.47,460.17Z"
            transform="translate(-141.61 -283.67)"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'journal-learning-loop-learning'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.46 283.46"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g
          id="symbol"
          style="transform: translate(-839px, -45px) scale(10.5);"
        >
          <path
            class="fill-white"
            d="m93.52,12.3c-2.78.02-5.01,2.35-4.99,5.13.01,1.79.95,3.35,2.37,4.23l.15,2.98c0,1.04.72,1.88,1.76,1.87h1.61c1.04-.02,1.74-.86,1.74-1.9l.11-2.98c1.4-.9,2.32-2.48,2.31-4.26-.02-2.78-2.28-5.08-5.06-5.06Zm3.04,5.2l-2.85,2.85c-.4.4-1.06.4-1.47,0l-1.7-1.7c-.4-.4-.4-1.06,0-1.46h0c.4-.41,1.06-.41,1.46,0l.97.97,2.12-2.12c.4-.4,1.06-.4,1.46,0s.4,1.06,0,1.46Z"
          />
          <g>
            <g>
              <line
                class="fill-white"
                x1="93.52"
                y1="10.26"
                x2="93.52"
                y2="8.74"
              />
              <path
                class="fill-white"
                d="m93.51,10.92c-.36,0-.66-.3-.66-.66v-1.52c0-.37.3-.66.66-.66h0c.36,0,.66.3.66.66v1.52c0,.37-.3.66-.66.66h0Z"
              />
            </g>
            <g>
              <line
                class="fill-white"
                x1="97.08"
                y1="11.2"
                x2="97.84"
                y2="9.89"
              />
              <path
                class="fill-white"
                d="m97.08,11.86c-.11,0-.23-.03-.33-.09-.32-.18-.43-.59-.24-.9l.76-1.31c.18-.32.59-.43.9-.24.32.18.43.59.24.9l-.76,1.31c-.12.21-.35.33-.57.33Z"
              />
            </g>
            <g>
              <line
                class="fill-white"
                x1="99.71"
                y1="13.79"
                x2="101.03"
                y2="13.03"
              />
              <path
                class="fill-white"
                d="m99.71,14.45c-.23,0-.45-.12-.57-.33-.18-.32-.07-.72.24-.9l1.32-.76c.32-.18.72-.07.9.24.18.32.07.72-.24.9l-1.32.76c-.1.06-.22.09-.33.09Z"
              />
            </g>
            <g>
              <line
                class="fill-white"
                x1="90.03"
                y1="11.2"
                x2="89.27"
                y2="9.89"
              />
              <path
                class="fill-white"
                d="m90.03,11.86c-.23,0-.45-.12-.57-.33l-.76-1.31c-.18-.32-.07-.72.24-.9.32-.18.72-.07.9.24l.76,1.31c.18.32.07.72-.24.9-.1.06-.22.09-.33.09Z"
              />
            </g>
            <g>
              <line
                class="fill-white"
                x1="87.4"
                y1="13.79"
                x2="86.09"
                y2="13.03"
              />
              <path
                class="fill-white"
                d="m87.4,14.45c-.11,0-.23-.03-.33-.09l-1.32-.76c-.32-.18-.43-.59-.24-.9.18-.32.59-.43.9-.24l1.32.76c.32.18.43.59.24.9-.12.21-.35.33-.57.33Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'journal-learning-loop-action'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.46 283.46"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g
          id="symbol"
          style="transform: translate(-1257px, -45px) scale(10.5);"
        >
          <path
            class="fill-white"
            d="m142.28,18.62l-1.67-2.9-.17-.3-.17.3-1.67,2.9-.17.3h1.36c-.35,3.16-3.05,5.61-6.26,5.61-.34,0-.62.28-.62.62s.28.62.62.62c3.89,0,7.15-2.99,7.5-6.84h1.43l-.17-.3Z"
          />
          <path
            class="fill-white"
            d="m133.52,11.93c.34,0,.62-.28.62-.62s-.28-.62-.62-.62c-3.89,0-7.15,2.99-7.5,6.84h-1.43l.17.3,1.67,2.9.17.3.17-.3,1.67-2.9.17-.3h-1.36c.35-3.16,3.05-5.61,6.26-5.61Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'journal-learning-loop-reaction'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.46 283.46"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g
          id="symbol"
          style="transform: translate(-1252px, -1142px) scale(10.5);"
        >
          <path
            class="fill-white"
            d="m137.37,113.79c.27-.27.27-.71,0-.97-.27-.27-.71-.27-.97,0l-7.75,7.75s0,0,0,0l-.7-.7-.24-.24-.09.33-.87,3.23-.09.33.33-.09,3.23-.87.33-.09-.24-.24-.7-.7s0,0,0,0l7.75-7.75Z"
          />
          <path
            class="fill-white"
            d="m138.88,120.74l-3.23.87-.33.09.24.24.7.7-7.75,7.75c-.27.27-.27.71,0,.97.13.13.31.2.49.2s.35-.07.49-.2l7.75-7.75.7.7.24.24.09-.33.87-3.23.09-.33-.33.09Z"
          />
        </g>
      </svg>
    </template>
    <template v-else-if="name === 'journal-learning-loop-reflection'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.46 283.46"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g
          id="symbol"
          style="transform: translate(-831px, -1156px) scale(10.5);"
        >
          <path
            class="fill-white"
            d="m86.9,130.13l1.34-3.37,2.38,2.38-3.37,1.34c-.31.12-.46-.03-.34-.34Zm13.1-10.43c.24-.24.24-.62,0-.86l-1.52-1.52c-.24-.24-.62-.24-.86,0l-.82.82,2.38,2.38.82-.82Zm-1.39,1.39l-2.38-2.38-7.54,7.49,2.38,2.38,7.54-7.49Z"
          />
        </g>
      </svg>
    </template>

    <template v-else-if="name === 'zly-match'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 142 142"
        class="icon-v3"
      >
        <circle
          class="fill-white"
          cx="71"
          cy="71"
          r="71"
        />
        <path
          class="svg-background-circle"
          d="m71,0C31.79,0,0,31.79,0,71c0,21.89,9.91,41.45,25.48,54.48l32.37-30.25c-.69-2.91.18-6.07,2.61-8.33l8.27-7.69c3.8-3.54,9.98-3.55,13.8-.02,3.82,3.53,3.83,9.26.02,12.8l-8.27,7.69c-2.49,2.32-6,3.11-9.19,2.4l-31.39,29.34c10.84,6.71,23.62,10.59,37.3,10.59,39.21,0,71-31.79,71-71S110.21,0,71,0Zm2.77,114.52c-4.31,0-8.31-.75-11.96-2.09l5.32-4.97c.08,0,.16.01.24.01,4.09,0,7.94-1.48,10.83-4.17l8.27-7.69c5.96-5.54,5.94-14.54-.03-20.08-2.89-2.67-6.73-4.14-10.8-4.14s-7.94,1.48-10.83,4.17l-8.27,7.69c-2.89,2.69-4.47,6.25-4.47,10.04,0,0,0,.01,0,.02l-6.71,6.27c-3.97-5.82-6.08-12.71-5.81-19.5.58-14.56,12.1-26.64,17.43-37.3,2.13,10.51-2.78,17.04,5.28,19.45,12.57,3.76,20.38-19.14,7.84-44.38,56.41,41.33,40.97,96.66,3.68,96.66Z"
        />
      </svg>
    </template>

    <template v-else-if="name === 'zly-intention'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.46 283.46"
        class="icon-v3"
      >
        <g id="background-circle">
          <circle
            class="svg-background-circle"
            cx="141.76"
            cy="141.76"
            r="141.73"
          />
        </g>
        <g id="symbol">
          <path
            class="fill-white"
            d="m73.73,98.41c3.27-23.16,23.21-41.03,47.26-41.03,20.55,0,38.12,13.06,44.83,31.31,4.79-2.56,10.24-4.02,16.04-4.02,18.8,0,34.1,15.3,34.1,34.1,0,0,0,.02,0,.03.18,0,.34-.03.52-.03,18.8,0,34.1,15.3,34.1,34.1s-15.3,34.1-34.1,34.1H76.66c-24.44,0-44.34-19.89-44.34-44.34s18.32-42.71,41.41-44.23Z"
          />
          <path
            class="svg-background-circle"
            d="m99.96,144.96l1.18-5.22-4.02-3.53s0,0,0,0l-10.87-9.55-4.23-3.71c-2.21-1.94-1.62-3.76,1.31-4.03l10-.93,15.34-1.42,6.09-14.15,3.97-9.23c1.16-2.71,3.07-2.71,4.24,0l3.97,9.23,6.09,14.15,15.34,1.42,10,.93c2.93.27,3.52,2.09,1.31,4.03l-15.09,13.26s0,0,0,0l-4.02,3.53,1.18,5.22s0,0,0,0l4.42,19.6c.65,2.88-.89,4-3.43,2.49l-14.88-8.83-7.01-4.16-13.24,7.86-8.64,5.13c-2.53,1.5-4.07.38-3.43-2.49l2.21-9.8,2.21-9.8s0,0,0,0Z"
          />
        </g>
      </svg>
    </template>

    <template v-else-if="isFontAwesome">
      <FontAwesomeIcon
        :class="[name, { 'icon-v3': isV3Style, spin }]"
        :icon="name"
        class="font-awesome inside-icon"
      />
      <!--        :spin="true"--> <!-- fa-spin breaks position -->
    </template>

    <template v-else-if="isCustomImage">
      <div class="inside-icon">
        <slot name="custom-image" />
      </div>
    </template>

    <template v-else-if="name">
      <!-- all other, use imported SVG icon -->
      <component
        :is="getComponentFor(name)"
        :class="name"
        class="inside-icon"
      />
    </template>

    <template v-else>
      <!-- ? -->
    </template>

    <div
      v-if="text"
      class="text-inside-icon"
    >
      {{ text }}
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { computed, defineAsyncComponent } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { useGlobalStore } from '@/stores/global';

const props = defineProps({
  showOuterCircle: {
    type: Boolean,
    default: true,
  },
  iconName: {
    // 2023-01-16: renamed "name" to "iconName" to prevent using the same prop name as the AppFormGroupWithValidation "name" prop (easier to search)
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  active: {
    type: Boolean,
    default: false,
  },
  activeWithoutColor: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  desaturatedBorder: {
    type: Boolean,
    default: false,
  },
  flip: {
    type: Boolean,
    default: false,
  },
  highlighted: {
    type: Boolean,
    default: false,
  },
  red: {
    type: Boolean,
    default: false,
  },
  redRing: {
    type: Boolean,
    default: false,
  },
  hoverable: {
    type: Boolean,
    default: true,
  },
  noBackground: {
    type: Boolean,
    default: false,
  },
  noBorder: {
    type: Boolean,
    default: false,
  },
  isFontAwesome: {
    type: Boolean,
    default: false,
  },
  isCustomImage: {
    type: Boolean,
    default: false,
  },
  isV3Style: {
    type: Boolean,
    default: false,
  },
  useV3IconBackground: {
    type: Boolean,
    default: false,
  },
  spin: {
    type: Boolean,
    default: false,
  },
  textInsideIcon: {
    type: String,
    default: '',
  },
});

defineEmits<{
  click: [event: Event];
}>();

const globalStore = useGlobalStore();

const v3Icons: {
  [key: string]: string;
} = {
  // 'impulse-area': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Impulze-Ball_Blue_001.png',
  // 'impulse-area-active': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Impulze-Ball_Red_001.png',
  // calendar: '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Calendar_007.png',
  // 'calendar-active': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Calendar_007_red.png',
  // done: '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Check_Done_001.png',
  // 'anticipation-and-reflection': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Individual-Empowerment_001.png',
  // languages: '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Language_005.png',
  // 'learning-and-doing': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_L&D_Action_003.png',
  // pencil: '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Pen_Journal_003.png',
  // 'pencil-active': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Pen_Journal_COLRD01_003.png',
  // logbook: '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Pen_Journal_003.png', // same as pencil
  // 'logbook-active': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Pen_Journal_COLRD01_003.png',
  // 'knowledge-base': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Process-Progress_002.png',
  // skip: '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Skip_002.png',
  // 'talent-manual-icon': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Talent-Manual_003.png',
  // 'learn-more': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Understanding-more_003.png',
  // 'learn-more-active': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Understanding-more_COLRD01_003.png',
  // video: '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Video_002.png',
  // 'video-active': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Video_002_red.png',
  // 'impulse-doing': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Doing-Action_001.png',
  // 'impulse-doing-active': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Doing-Action_COLRD01_001.png',
  // cancel: '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Cancel_001.png',
  // percent: '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Percent_005.png',
  // 'percent-active': '/img/icons/v3/IMP_2020_C20_System_Design_Icons_Percent_005-active.png',
};

const name = computed(() => props.iconName);
const useV3Icon = computed(() => globalStore.isAppImp && globalStore.isThemeV3 && Object.keys(v3Icons)
  .includes(props.iconName));

function getComponentFor(nameArg: string) {
  if (!nameArg) {
    return null;
  }
  return defineAsyncComponent(() => import(`../../assets/icons/${nameArg}.svg`));
}
</script>

<style
  lang="scss"
  scoped
>
.app-icon {
  position: relative;
  cursor: pointer;
  $size: $font-28px;
  width: $size;
  height: $size;

  &.small {
    $size: $font-22px;
    width: $size;
    height: $size;
  }

  &.smaller {
    $size: $font-15px;
    width: $size;
    height: $size;
  }

  &.not-hoverable,
  &.not-clickable {
    cursor: default;
  }

  .circle-background {
    fill: #272d33;
    fill-opacity: 1;
    fill-rule: nonzero;
    stroke: none;

    .theme-v3e & {
      fill: #fff;
    }

    &.no-background {
      fill: none;
    }
  }

  .circle-ring {
    stroke: var(--accent-color);

    &.no-border {
      stroke: none;
    }
  }

  &.flip {
    .inside-icon,
    .icon-v3,
    :deep(.icon-v3) {
      transform: translate(-50%, -50%) scaleX(-1);
    }
  }

  &.highlighted {
    .inside-icon,
    .svg-background-circle {
      fill: var(--accent-color);
      color: var(--accent-color); // Font Awesome
    }
  }

  &.learning-color.active {
    .inside-icon,
    .svg-background-circle {
      fill: $theme-v3e-brown-1;
      color: $theme-v3e-brown-1; // Font Awesome
    }
  }

  &.red {
    .inside-icon {
      fill: $blush;
      color: $blush; // Font Awesome

      .app-jgo & {
        fill: $jgo-fire-bright;
        color: $jgo-fire-bright; // Font Awesome
      }
    }
  }

  &.red-ring {
    /*cursor: default;*/

    .circle-ring {
      stroke: #f0a18a;
    }
  }

  &.desaturated-border {
    .circle-ring {
      stroke: color.adjust($lightblue, $saturation: -100%, $space: hsl);
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;

    .svg-background-circle {
      fill: var(--text-color-1);
    }

    &.inside-icon {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 50%;
      max-height: 50%;
      fill: $light-primary;
      color: $light-primary; // Font Awesome

      &.spin {
        animation-name: spin;
        animation-delay: var(--fa-animation-delay, 0s);
        animation-direction: var(--fa-animation-direction, normal);
        animation-duration: var(--fa-animation-duration, 2s);
        animation-iteration-count: var(--fa-animation-iteration-count, infinite);
        animation-timing-function: var(--fa-animation-timing, linear);
        @keyframes spin {
          0% {
            transform: translate(-50%, -50%) rotate(0);
          }
          100% {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }
      }

      .theme-v3e & {
        //fill: #6C7780;
        //color: #6C7780;
        fill: var(--text-color-1);
        color: var(--text-color-1); // for Font Awesome icons
      }

      &.fa-key {
        transform: translate(-48%, -45%) rotate(-180deg) scale(0.9);
      }

      &.fa-hand-paper {
        transform: translate(-50%, -50%) scale(0.96);
      }

      &.fa-gear {
        transform: translate(-50%, -50%) scale(0.9);
      }

      &.fa-rotate-left {
        transform: translate(-50%, -50%) scale(0.8);
      }

      &.fa-rotate-right {
        transform: translate(-50%, -50%) scale(0.8);
      }

      &.pencil {
        /*transform: translate(-50%, -50%) scale(1.7);*/
        max-width: 88%;
        max-height: 88%;
      }

      &.email-at {
        transform: translate(-50%, -48%);
      }

      &.hr {
        transform: translate(-50%, -46%) scale(2);
      }

      &.done {
        max-width: 66%;
        max-height: 66%;
      }

      &.eye {
        transform: translate(-48%, -48%) scale(1.3);
      }

      &.bell {
        transform: translate(-48%, -50%) scale(0.9);
      }

      &.info {
        transform: translate(-50%, -50%) scale(0.8);
      }

      &.home {
        stroke: $light-primary;
      }
    }
  }

  &.hoverable:hover {
    svg:not(.icon-v3) {
      .svg-background-circle {
        fill: var(--text-color-2);
      }

      &.inside-icon {
        fill: var(--columns-background);
        color: var(--columns-background); // Font Awesome

        .theme-v3e & {
          fill: var(--text-color-2);
          color: var(--text-color-2); // Font Awesome
        }

        &.home {
          stroke: var(--columns-background);
        }
      }
    }
  }

  &.active {
    .circle-ring {
      stroke: var(--accent-color);
    }

    svg {
      .svg-background-circle {
        fill: var(--accent-color);
      }

      &.inside-icon {
        fill: var(--accent-color);
        color: var(--accent-color); // for Font Awesome icons
      }
    }

    .icon-v3,
    :deep(.icon-v3) {
      width: 63%; // pushed down
    }
  }

  &.disabled {
    cursor: default;

    .circle-ring {
      stroke: #f0a18a;
    }

    svg {
      &.inside-icon {
        fill: color.adjust($theme-v2-columns-background, $lightness: -10%, $space: hsl);
        color: color.adjust($theme-v2-columns-background, $lightness: -10%, $space: hsl); // Font Awesome
      }
    }

    .theme-v3e & {
      filter: opacity(68%);

      .svg-background-circle {
        fill: var(--text-color-2);
      }
    }
  }

  &.v3-background {
    // v3 background is dark blue
    .inside-icon {
      .theme-v3e & {
        fill: white !important;
        color: white !important; // for Font Awesome icons; keep white when active, only the background changes
      }

      &.ellipsis-h,
      &.pencil-alt,
      &.eye {
        width: 31%;
      }
    }
  }

  // Custom sizes
  &.knowledge-base {
    svg.inside-icon {
      max-width: 50%;
      max-height: 50%;
    }
  }

  &.logbook {
    svg.inside-icon {
      max-width: 54%;
      max-height: 54%;
    }
  }

  &.sixpack {
    svg.inside-icon {
      max-width: 60%;
      max-height: 60%;
    }
  }

  &.learning-nugget {
    svg.inside-icon {
      max-width: 43%;
      max-height: 43%;
    }
  }

  &.logbook-archive {
    svg.inside-icon {
      max-width: 90%;
      max-height: 90%;
    }
  }

  &.talent-manual-icon {
    svg.inside-icon {
      /*max-width: 60%;*/
      /*max-height: 38%;*/
    }
  }

  &.at {
    svg.inside-icon {
      .theme-v3e & {
        max-width: 40%;
        max-height: 40%;
      }
    }
  }

  &.file {
    svg.inside-icon {
      .theme-v3e & {
        max-width: 40%;
        max-height: 40%;
      }
    }
  }

  &.vertical-ellipsis {
    svg.inside-icon {
      .theme-v3e & {
        max-width: 40%;
        max-height: 40%;
      }
    }
  }

  &.neumorphic-circle {
    border-radius: 50%;

    .icon-v3,
    :deep(.icon-v3) {
      // the background circle inside the neumorphic circle
      width: 69.5%;
      max-width: unset;
      max-height: unset;
      overflow: visible;
    }

    @extend .neumorphic-bg-and-shadow-1-sharper;

    &.dark {
      @extend .neumorphic-bg-and-shadow-dark-sharper;

      .svg-background-circle {
        fill: var(--dark);
      }
    }

    &:active:not(.disabled):not(.not-clickable),
    &.active,
    &.active-without-color {
      @extend .neumorphic-bg-and-shadow-1-selected-sharper;

      &.dark {
        @extend .neumorphic-bg-and-shadow-dark-selected-sharper;
      }

      .icon-v3,
      :deep(.icon-v3) {
        width: 63%; // pushed down
      }
    }
  }

  &.smaller-check-icon {
    svg.inside-icon {
      max-width: 38%;
      max-height: 38%;
    }
  }

  .icon-v3,
  :deep(.icon-v3) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 69.5%;
    transform: translate(-50%, -50%);
    border-radius: 50%;

    .navbar-nav & {
      top: 0;
      left: 0;
      width: 100%;
      transform: none;
    }
  }

  &.no-background {
    .svg-background-circle {
      fill: none !important;
    }
  }
}

.nav-link.active, /* when :active set manually at BNavItem */
.router-link-active { /* when BNavItem detects route automatically */
  .app-icon,
  .app-icon.hoverable:hover {
    svg {
      .svg-background-circle {
        fill: var(--accent-color);
      }

      &.inside-icon {
        fill: var(--accent-color);
        color: var(--accent-color); // Font Awesome
      }
    }
  }
}

/* .app-icon {
  background-image: radial-gradient(50% 49%, #312F2F 50%, #202020 82%, rgba(255, 255, 255, 0.36) 100%);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  fill: $light-primary;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 50%;
    max-height: 50%;
  }

  &.knowledge-base {
    svg {
      max-width: 40%;
      max-height: 40%;
    }
  }

  &:hover {
    fill: $battleship-grey-three;
  }
}

.active .app-icon,
.active .app-icon:hover {
  fill: $lightblue;
} */

//
.talent-manual-icon-option {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
  max-height: 50%;
}

.text-inside-icon {
  z-index: 1;

  position: absolute;
  top: 50%;
  left: 50%;
  /*cursor: pointer;*/
  /*width: 100%;*/
  /*height: 100%;*/
  transform: translate(-50%, -45%);

  color: $light-primary;
  font-size: $font-08px;
  line-height: $font-08px;
  letter-spacing: 0.03vw;

  text-transform: uppercase;
  font-weight: bold;

  .theme-v3e & {
    color: var(--text-color-1);
  }
}

svg.circle-active-basic-development {
  overflow: visible; // fix SVG being cut by 1px at the top/side on Firefox
  position: absolute;
  top: 0;
  left: 0;
  width: 69.5%;
  max-width: unset !important;
  max-height: unset !important;

  transform: translate(-50%, -50%) !important;

  .active & {
    width: 63%; // pushed down
  }

  .background {
    fill: var(--columns-background); // #4A4F54;
  }

  .color {
    fill: #6E777F;

    .theme-v3e & {
      fill: var(--text-color-1);
    }
  }

  .stroke {
    stroke: #6E777F;

    .theme-v3e & {
      stroke: var(--text-color-1);
    }
  }

  .active & {
    //left: unset;
    //right: 0;

    //svg {
    .color {
      fill: var(--accent-color);
    }

    .stroke {
      stroke: var(--accent-color);
    }

    //}
  }
}

.circle-of-elements-avatar-face {
  fill: var(--text-color-1);

  .active & {
    fill: var(--accent-color);
  }
}

.circle-of-elements-avatar-face-bg {
  fill: var(--columns-background);
}

.fill-white {
  fill: #fff;
}
</style>
