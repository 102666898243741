// Pinia Store
import { computed, inject, ref } from 'vue';
// import type { App } from 'vue';
import { defineStore } from 'pinia';
import type { AccountReturn, ClientDetailsRaw } from '@/stores/api';
import type { ProfileClient, ProfileRaw } from '@/stores/account';
import type { AxiosResponse, AxiosStatic } from 'axios';
import { useAccountStore } from '@/stores/account';
import { useAuthStore } from '@/stores/auth';
import { useGlobalStore } from '@/stores/global';
import { useImpulsesStore } from '@/stores/impulses';
import type { Impulse } from '@/stores/impulses';
import { differenceInMinutes } from 'date-fns';

import type { Entity, EntityBlockPerson } from '@/components/impulse-area/EntityBlockWithChart.vue';
import type { Aggregator } from '@/views/ImpulseArea.vue';
import type { TableClientsRow } from '@/components/management/TableClients.vue';
import type { TableAccountsRow } from '@/components/management/TableAccounts.vue';
import type { DigOrPerfectCandidateEntityBlock } from '@/components/tly/treasure-quest/JobCheck.vue';

console.log('Pinia Entities store is being created.'); // no access to Vue.prototype.$log here yet

export interface ActiveOrBasicRow {
  alias: string;
  color: string;
  fix_values: null;
  label: string;
  type: string;
  value: number;
}

export interface ProfileBlockActiveOrBasic {
  chart_image: null | string;
  chart_size: null | {
    height: number;
    width: number;
  };
  color: null | string;
  element: null | string;
  mask: string;
  rows: ActiveOrBasicRow[];
  segment: null | number;
  x: null | number;
  y: null | number;
}

export interface ProfileBlockDevelopment {
  color: string;
  element: string;
  segment: number;
  x: number;
  y: number;
}

export interface PersonRaw {
  active: ProfileBlockActiveOrBasic;
  avatar: string | null;
  basic: ProfileBlockActiveOrBasic;
  can_access_talent_manual: boolean;
  development_primary: ProfileBlockDevelopment;
  development_secondary: ProfileBlockDevelopment;
  entity_type_id: number;
  first_name: string;
  group_condition: boolean;
  has_talent_manual: boolean;
  id: number;
  individual_condition: boolean;
  is_active: boolean;
  is_avatar: boolean;
  is_impulse_generation_active: boolean;
  is_selected: null;
  last_name: string;
  nickname: string;
  position: null;
  privacy_control: boolean;
  profile_owner: boolean;
  requires_attention: boolean;
  role: {
    id: number;
    name: string;
  };
  roles: {
    id: number;
    name: string;
  }[];
  session_user: boolean;
  talent_manual_access: boolean;
  //
  impulse?: null | Impulse;
}

export interface EntityBlockRaw { // from own account, or from /session/entities/${id}/block
  active: ProfileBlockActiveOrBasic;
  avatar: string;
  basic: ProfileBlockActiveOrBasic;
  can_access_talent_manual: boolean;
  entity_type_id: number;
  group_condition: boolean;
  has_talent_manual: boolean;
  id: number;
  individual_condition: boolean;
  is_avatar: boolean;
  nickname: string;
  position: string;
  profile_owner: boolean;
  requires_attention: boolean;
  role: {
    id: number;
    name: string;
  };
  session_user: boolean;
}

export interface CockpitPersonRaw {
  active: ProfileBlockActiveOrBasic;
  avatar: string;
  basic: ProfileBlockActiveOrBasic;
  entity_type_id: number;
  first_name: string;
  id: number;
  last_name: string;
  nickname: string;
  position: null;
  requires_attention: boolean;
}

export interface AccountClientRaw {
  avatar: null | string;
  display_name: string;
  id: number;
  inserted_at: string;
  is_consulting: boolean;
}

export interface AccountRaw {
  client: AccountClientRaw;
  display_name: string;
  id: number;
}

export interface UserRawForAdmin { // /users/${id} response
  account: AccountRaw;
  activated_at: string;
  active: boolean;
  avatar: string;
  birth_day: null;
  birth_month: null;
  birth_year: number;
  country_nationality: null | {
    aircraft_code: string;
    code: string;
    id: number;
    name: string;
  };
  country_origin: null | {
    aircraft_code: string;
    code: string;
    id: number;
    name: string;
  };
  country_residence: {
    aircraft_code: string;
    code: string;
    id: number;
    name: string;
  };
  country_work: null | {
    aircraft_code: string;
    code: string;
    id: number;
    name: string;
  };
  email: string;
  first_name: string;
  gender: {
    data: { // TODO backend should remove this "data" wrapper?
      id: number;
      name: string;
    };
  };
  has_talent_manual: boolean;
  id: number;
  is_avatar: boolean;
  is_example_user: boolean;
  is_service_contact: boolean;
  last_activity_datetime: string;
  last_name: string;
  locked: boolean;
  mask: string;
  native_language: null;
  nickname: string;
  onboarding_step: number;
  personal_email: null;
  position: null;
  receive_invitation_cc: boolean;
  registered_at: string;
  roles: {
    id: number;
    name: string;
  }[];
  service_contact_order: null;
  settings: {
    is_example_user: boolean;
    is_service_contact: boolean;
    receive_invitation_cc: boolean;
    service_contact_order: null;
  };
  timezone: string;
  username: string;
}

interface IndividualResponse {
  data: {
    empty_chart: null | string;
    motivation: null | string;
    next_impulse_datetime: null | string;
    no_permission_chart: null | string;
    people: PersonRaw[];
  };
}

export interface GroupRaw {
  active: ProfileBlockActiveOrBasic;
  avatar: string;
  basic: ProfileBlockActiveOrBasic;
  entity_type_id: number;
  has_talent_manual: boolean;
  id: number;
  nickname: string;
  position: null | string;
  requires_attention: boolean;
}

export type Group = GroupRaw & {
  visible: boolean;
  isActiveMode: boolean;
};

interface GroupsResponse {
  data: {
    data: GroupRaw[];
    motivation: null | string;
  };
}

let loadingPeople = false;
let loadingPeopleToRefreshImages = false;
let loadingGroups = false;
let lastErrorFetchingOwnAvatarTime: Date;
let lastErrorFetchingImageTime: Date;

const useEntitiesStore = defineStore('entities', () => {
  const $log: any = inject('$log');
  const $http: undefined | AxiosStatic = inject('$http');

  const people = ref<EntityBlockPerson[]>([]);
  const peoplePromise = ref<undefined | Promise<undefined | IndividualResponse>>(undefined);
  const selectedEntityType = ref<null | number>(null);
  const selectedPerson = ref<null | EntityBlockPerson>(null);
  const groups = ref<Group[]>([]);
  const groupsPromise = ref<undefined | Promise<undefined | GroupsResponse>>(undefined);
  const selectedGroup = ref<null | Group>(null);
  const previouslySelectedPersonBeforeSelectingTeamOrGroup = ref<null | EntityBlockPerson>(null);
  const temporarySelectedEntity = ref<null | EntityBlockPerson | UserRawForAdmin | EntityBlockPerson | Aggregator | DigOrPerfectCandidateEntityBlock | Entity>(null);
  const temporarySelectedClient = ref<null | ClientDetailsRaw | TableClientsRow | ProfileClient>(null);
  const temporarySelectedClientAccount = ref<null | AccountReturn | TableAccountsRow>(null);

  const peopleWithProfile = computed(() => people.value.filter((person: EntityBlockPerson) => !!person.id));
  const ownProfile = computed((): undefined | EntityBlockPerson => people.value.find((person: EntityBlockPerson) => person.session_user === true));

  function resetState() {
    people.value = [];
    peoplePromise.value = undefined;
    selectedEntityType.value = null;
    selectedPerson.value = null;
    groups.value = [];
    groupsPromise.value = undefined;
    selectedGroup.value = null;
    previouslySelectedPersonBeforeSelectingTeamOrGroup.value = null;
    temporarySelectedEntity.value = null;
    temporarySelectedClient.value = null;
    temporarySelectedClientAccount.value = null;
  }

  function selectPerson(payload: undefined | EntityBlockPerson) {
    if (!payload) {
      $log.debug('selectPerson: no payload');
      selectedPerson.value = null;
      selectedGroup.value = null;
    } else {
      $log.debug('selectPerson:', payload.id, payload.nickname);
      selectedPerson.value = payload;
      selectedGroup.value = null;
    }
  }

  function selectPersonById(personId: number) {
    const foundPerson = people.value.find((person: EntityBlockPerson) => person.id === personId);
    if (foundPerson) {
      selectedPerson.value = foundPerson;
      selectedGroup.value = null;
    } else {
      $log.debug(`selectPersonById: person with ID ${personId} not found. deselecting current person`);
      $log.debug('List of people:', people.value);
      // deselect
      selectedPerson.value = null;
      selectedGroup.value = null;
    }
  }

  function setTemporarySelectedEntity(payload: EntityBlockPerson | UserRawForAdmin | Aggregator | DigOrPerfectCandidateEntityBlock | Entity) {
    $log.debug('setTemporarySelectedEntity', ('id' in payload && payload.id) || ('questionnaireId' in payload && payload.questionnaireId), payload.nickname);
    temporarySelectedEntity.value = payload;
  }

  function clearTemporarySelectedEntity() {
    $log.debug('clearTemporarySelectedEntity');
    temporarySelectedEntity.value = null;
  }

  function setTemporarySelectedClient(payload: ClientDetailsRaw | TableClientsRow | ProfileClient) {
    $log.debug('setTemporarySelectedClient', payload.id, ('description' in payload ? payload.description : '(no description property'));
    temporarySelectedClient.value = payload;
  }

  function clearTemporarySelectedClient() {
    $log.debug('clearTemporarySelectedClient');
    temporarySelectedClient.value = null;
  }

  function setTemporarySelectedClientAccount(payload: AccountReturn | TableAccountsRow) {
    $log.debug('setTemporarySelectedClientAccount', payload.id, payload.description);
    temporarySelectedClientAccount.value = payload;
  }

  function clearTemporarySelectedClientAccount() {
    $log.debug('clearTemporarySelectedClientAccount');
    temporarySelectedClientAccount.value = null;
  }

  function selectGroupById(groupId: number) {
    const foundGroup = groups.value.find((group) => group.id === groupId);
    if (foundGroup) {
      $log.debug('selectGroupById, found group:', foundGroup.id, foundGroup.nickname);
      selectedPerson.value = null;
      selectedGroup.value = foundGroup;
    } else {
      $log.debug(`selectGroupById: group with ID ${groupId} not found.`);
      $log.debug('List of groups:', groups.value);
    }
  }

  function savePreviouslySelectedPersonBeforeSelectingTeamOrGroup(personId: number) {
    const foundPerson = people.value.find((person: EntityBlockPerson) => person.id === personId);
    if (foundPerson) {
      $log.debug('savePreviouslySelectedPersonBeforeSelectingTeamOrGroup, found person:', foundPerson.id, foundPerson.nickname);
      previouslySelectedPersonBeforeSelectingTeamOrGroup.value = foundPerson;
    } else {
      $log.debug(`savePreviouslySelectedPersonBeforeSelectingTeamOrGroup: person with ID ${personId} not found.`);
      $log.debug('List of people:', people.value);
    }
  }

  async function getLastSelectedUserAndSelectThem() {
    const ENTITY_TYPES = {
      PERSON: 1,
      GROUP: 2,
    };
    try {
      $log.debug('Will now get the selected entity.');

      interface SelectedEntityData {
        id: number;
        type: 1 | 2;
        user_id: number;
      }

      const response: undefined | AxiosResponse<SelectedEntityData> = await $http?.get('/session/cockpit/entities/selected', { signal: undefined });

      if (response?.data && response.data.type && response.data.id) {
        $log.debug('Got the selected entity');
        switch (response.data.type) {
          case ENTITY_TYPES.PERSON:
            $log.debug('Selected entity type is PERSON');
            selectPersonById(response.data.id);
            break;
          case ENTITY_TYPES.GROUP:
            $log.debug('Selected entity type is GROUP');
            selectGroupById(response.data.id);
            break;
          // no default
        }
        if ('user_id' in response.data) {
          $log.debug('Selected entity has user_id, save it before selecting team or group');
          savePreviouslySelectedPersonBeforeSelectingTeamOrGroup(response.data.user_id);
        }
        $log.debug(`Saving selected entity type (${response.data.type}).`);
        selectedEntityType.value = response.data.type;
      } else {
        $log.debug('getLastSelectedUserAndSelectThem: check response.data content (type/id not found):', response?.data);
      }
      $log.debug('getLastSelectedUserAndSelectThem: resolving.');
    } catch (error) {
      $log.error('getLastSelectedUserAndSelectThem error:', error);
      $log.debug('Selecting own profile');
      selectPerson(ownProfile.value);
    } finally {
      // do nothing
    }
  }

  async function loadPeopleAndGroups(payload?: {
    keepPreviousImages?: boolean;
    toRefreshImages?: boolean;
    groupsOnly?: boolean;
    resetSelectedPerson?: boolean;
    ignoreCurrentPromise?: boolean;
  }) {
    const keepPreviousImages = payload && payload.keepPreviousImages === true;
    const toRefreshImages = payload && payload.toRefreshImages === true;
    const groupsOnly = payload && payload.groupsOnly === true;
    const resetSelectedPerson = payload && payload.resetSelectedPerson === true;
    const ignoreCurrentPromise = payload && payload.ignoreCurrentPromise === true;

    let fetchPeoplePromise;
    if (!groupsOnly) {
      if (!peoplePromise.value || ignoreCurrentPromise) {
        peoplePromise.value = (async () => {
          const authStore = useAuthStore();
          if (!authStore.isAuthenticated) {
            $log.debug('Not authenticated, skipping.');
            throw new Error('Trying to fetch people and attitude but is not authenticated.');
          }
          if ((!toRefreshImages && loadingPeople && !ignoreCurrentPromise)
            || (toRefreshImages && loadingPeopleToRefreshImages && !ignoreCurrentPromise)) {
            const message = 'fetchPeoplePromise: Already fetching people';
            $log.debug(message);
            throw new Error(message);
          }
          loadingPeople = true;
          const globalStore = useGlobalStore();
          globalStore.increaseGlobalLoadingCounter();
          if (toRefreshImages) {
            loadingPeopleToRefreshImages = true;
          }
          try {
            const globalStore = useGlobalStore();
            const url = globalStore.isAppJgo ? '/session/entities/individual' : '/session/cockpit/individual';
            const response: undefined | IndividualResponse = await $http?.get(url, { signal: undefined });
            if (!response) {
              return undefined;
            }
            const peopleData = response.data.people
              .map((person) => ({
                ...person,
                visible: 'is_active' in person ? person.is_active : true,
                isActiveMode: true,
              }));

            // individualMotivation.value = response.data.motivation || '';
            const impulsesStore = useImpulsesStore();
            impulsesStore.setNextImpulseDateTime(response.data.next_impulse_datetime || null);

            if (keepPreviousImages) {
              // keep current images URLs
              // $log.debug('people from server', people);
              // $log.debug('previous people', people.value);
              peopleData.forEach((person, index) => {
                peopleData[index].avatar = people.value[index].avatar;
                peopleData[index].active.chart_image = people.value[index].active.chart_image;
                peopleData[index].basic.chart_image = people.value[index].basic.chart_image;
              });
            }

            people.value = peopleData;

            // Keep previously selected person
            if (!resetSelectedPerson && selectedPerson.value && !globalStore.isAppJgo) {
              await getLastSelectedUserAndSelectThem();
            }
            if (!keepPreviousImages) {
              if (!globalStore.isAppJgo && (resetSelectedPerson || (!selectedPerson.value && !selectedGroup.value))) {
                $log.debug('No selected person nor group, will select the last selected');
                await getLastSelectedUserAndSelectThem();
                return response;
              }
              // We already have the selected person
              return response;
            }
            return response;
          } catch (error) {
            throw new Error(`Error fetching ImpulseArea data: ${typeof error === 'object' && error && 'message' in error && error.message}`);
          } finally {
            loadingPeople = false;
            const globalStore = useGlobalStore();
            globalStore.decreaseGlobalLoadingCounter();
            loadingPeopleToRefreshImages = false;
            peoplePromise.value = undefined;
          }
        })();
      } else {
        $log.debug('fetchPeoplePromise: peoplePromise.value exists; reusing the same promise.');
      }
      fetchPeoplePromise = peoplePromise.value;
    }

    const accountStore = useAccountStore();
    const globalStore = useGlobalStore();
    let fetchGroupsPromise;
    if (accountStore.userRoleIsImpTeamLeader || (globalStore.isThemeV3 && accountStore.userRoleIsImpTeamMember)) {
      if (!groupsPromise.value || ignoreCurrentPromise) {
        groupsPromise.value = (async () => {
          const authStore = useAuthStore();
          if (!authStore.isAuthenticated) {
            $log.debug('Not authenticated, skipping.');
            throw new Error('Trying to fetch people and attitude but is not authenticated.');
          }
          if (loadingGroups && !ignoreCurrentPromise) {
            const message = 'fetchGroupsPromise: Already fetching groups';
            $log.debug(message);
            throw new Error(message);
          }
          loadingGroups = true;
          try {
            const response: undefined | GroupsResponse = await $http?.get('/session/groups', { signal: undefined });
            if (!response) {
              return undefined;
            }
            const groupsData: Group[] = response.data.data
              .map((group) => ({
                ...group,
                // visible: 'is_active' in group ? group.is_active : true,
                visible: true,
                isActiveMode: true,
              }));
            $log.debug('got groups');
            $log.debug(groupsData);

            groups.value = groupsData;

            // Keep previously selected group
            if (selectedGroup.value) {
              selectGroupById(selectedGroup.value.id);
            }
            $log.debug('OK, got groups, resolving');
            return response;
          } catch (error) {
            throw new Error(`Error fetching ImpulseArea data: ${typeof error === 'object' && error && 'message' in error && error.message}`);
          } finally {
            loadingGroups = false;
            groupsPromise.value = undefined;
          }
        })();
      } else {
        $log.debug('fetchGroupsPromise: groupsPromise.value exists; reusing the same promise.');
      }
      fetchGroupsPromise = groupsPromise.value;
    }

    let promises;
    if (groupsOnly) {
      promises = [fetchGroupsPromise];
    } else {
      promises = [fetchPeoplePromise, fetchGroupsPromise];
    }
    try {
      await Promise.all(promises);
      // ignore the values
      //   $log.debug('Promise.all.then values:');
      //   $log.debug(values);
      $log.debug('got people and got groups; resolving');
      // resolve();
    } catch (error) {
      // ignore the error
      //   $log.debug('Promise.all.catch error:');
      //   $log.error(error);
      const message = 'failed getting people or getting groups; rejecting';
      $log.error(message, error);
      throw new Error(`${message}. Error: ${error}`);
    } finally {
      // resolve();
    }
  }

  function updatePeopleListButKeepTheImagesURLsToPreventLoadingThemAgain() {
    // Workaround while the service is not split into two
    return loadPeopleAndGroups({ keepPreviousImages: true });
  }

  function updatePeopleListAndFetchTheImagesURLsToo() {
    // Workaround while the service is not split into two
    return loadPeopleAndGroups({ toRefreshImages: false });
  }

  // function updateGroupsList() {
  //   return loadPeopleAndGroups({ groupsOnly: true });
  // }

  async function errorFetchingOwnAvatar(error: unknown) {
    const now = new Date();
    if (lastErrorFetchingOwnAvatarTime && differenceInMinutes(now, lastErrorFetchingOwnAvatarTime) < 1) {
      $log.debug('errorFetchingOwnAvatar: happened in less than one minute; skip it this time.');
      return null;
    }
    lastErrorFetchingOwnAvatarTime = now;
    $log.debug('Error fetching own avatar; will fetch profile. Error:', error);
    const accountStore = useAccountStore();
    await accountStore.fetchAccountOnly();
  }

  function errorFetchingImage(error: unknown) {
    const now = new Date();
    if (lastErrorFetchingImageTime && differenceInMinutes(now, lastErrorFetchingImageTime) < 1) {
      $log.debug('errorFetchingImage: happened in less than one minute; skip it this time.');
      return null;
    }
    lastErrorFetchingImageTime = now;
    $log.debug('Error fetching image; will get a new people list with updated URLs. Error:', error);
    // Get a fresh list
    return loadPeopleAndGroups({ toRefreshImages: true });
  }

  function updateProfileAvatarOnPeopleList(payloadProfile: ProfileRaw) {
    if (!payloadProfile) {
      $log.error('No profile on payload.');
    }

    const userInPeople = people.value.find((p: EntityBlockPerson) => p.id === payloadProfile.id);
    if (!userInPeople) {
      return;
    }
    userInPeople.avatar = payloadProfile.avatar;
  }

  return {
    //
    // State
    //
    people,
    selectedEntityType,
    selectedPerson,
    groups,
    groupsPromise,
    selectedGroup,
    previouslySelectedPersonBeforeSelectingTeamOrGroup,
    temporarySelectedEntity,
    temporarySelectedClient,
    temporarySelectedClientAccount,
    //
    // Getters
    //
    peopleWithProfile,
    ownProfile,
    //
    // Actions
    //
    resetState,
    selectPerson,
    selectPersonById,
    setTemporarySelectedEntity,
    clearTemporarySelectedEntity,
    setTemporarySelectedClient,
    clearTemporarySelectedClient,
    setTemporarySelectedClientAccount,
    clearTemporarySelectedClientAccount,
    selectGroupById,
    savePreviouslySelectedPersonBeforeSelectingTeamOrGroup,
    getLastSelectedUserAndSelectThem,
    loadPeopleAndGroups,
    updatePeopleListButKeepTheImagesURLsToPreventLoadingThemAgain,
    updatePeopleListAndFetchTheImagesURLsToo,
    // updateGroupsList,
    errorFetchingOwnAvatar,
    errorFetchingImage,
    updateProfileAvatarOnPeopleList,
  };
});

export { useEntitiesStore };
