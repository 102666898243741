<template>
  <div
    :class="[`app-${appName}`, `theme-${appTheme}`]"
    class="splash-screen-wrapper"
  >
    <template v-if="appName === 'imp' && appTheme === 'v3e'">
      <div class="v3e-logo-container">
        <img
          src="/img/IMP_2020_C20_Logo_Original_008_no_ball.png"
          alt=""
        >
        <img
          src="/img/IMP_2020_C20_Logo_Original_008_ball.png"
          alt=""
          class="ball"
        >
        <!--        <img-->
        <!--          src="/img/IMP_2020_C20_Logo_Original_008_ball_no_reflection.png"-->
        <!--          alt=""-->
        <!--          class="ball"-->
        <!--        >-->
        <!--        <img-->
        <!--          src="/img/IMP_2020_C20_Logo_Original_008_ball_reflection_cut.png"-->
        <!--          alt=""-->
        <!--          class="ball-reflection"-->
        <!--        >-->
      </div>
    </template>
    <template v-else>
      <div class="indicator-container">
        <div class="first-indicator" />
        <div class="second-indicator" />
      </div>

      <div class="v3e-logo-container">
        <template v-if="appName === 'zae'">
          <img
            src="/img/zae/zae_logo.png"
            alt=""
          >
        </template>
        <template v-if="appName === 'zly'">
          <img
            src="/img/zly/ZLY_2021_Logo_Original_Full_01_Purple-White_008.svg"
            alt=""
          >
        </template>
        <!--        <template v-else-if="appName === 'imp'">-->
        <!--          <img-->
        <!--            src="/img/IMP_2020_C20_Logo_Original_008.png"-->
        <!--            alt=""-->
        <!--          >-->
        <!--        </template>-->
        <template v-else-if="appName === 'jgo'">
          <template v-if="appTheme === 'v3e'">
            <img
              src="/img/jgo/JGO_2022_Logo_Original_Turquoise-White_008.png"
              alt=""
            >
          </template>
          <template v-else>
            <img
              src="/img/jgo/jgo-JGO_2019_CI_Logo_Original_Grey_SW_008.png"
              alt=""
            >
          </template>
        </template>
        <template v-else-if="appName === 'tly'">
          <img
            src="/img/tly/TLY_2021_CI_Logo_original_008.svg"
            alt=""
          >
        </template>
      </div>
    </template>
  </div>
</template>

<script
  setup
  lang="ts"
>
const appName = import.meta.env.VITE_APP_NAME;
// const appName = 'imp'; // debug
// const appName = 'jgo'; // debug
// const appName = 'tly'; // debug
// const appName = 'zly'; // debug

const AVAILABLE_THEMES = [
  { id: 'v2', name: 'v2, 2019' },
  { id: 'v3e', name: 'v3 e, 2020' },
];

const DEFAULT_THEME_INDEX = 1; // v3e

function getThemeFromEnv() {
  let envThemeId = AVAILABLE_THEMES[DEFAULT_THEME_INDEX].id; // the default, unless overridden by an env var
  if (window.ENV && window.ENV.THEME) {
    // set when starting the container, reading from AWS task definition environment variables
    envThemeId = window.ENV.THEME.toLowerCase();
  } else if (import.meta.env.VITE_APP_THEME) {
    // Not found on window.ENV, let's try the process
    envThemeId = import.meta.env.VITE_APP_THEME.toLowerCase();
  }
  return AVAILABLE_THEMES.find((theme) => theme.id === envThemeId) || AVAILABLE_THEMES[DEFAULT_THEME_INDEX];
}

const appTheme = getThemeFromEnv().id;
// const appTheme = 'v3e'; // debug
</script>

<style
  lang="scss"
  scoped
>
.splash-screen-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden !important;

  // variables from _variables.scss
  $theme-v2-columns-background: #b6c1cf;
  $theme-v3e-columns-background: #F1F4F9;
  $accent-imp: #F5005A;
  $accent-jgo-old: #41B883;
  $accent-jgo: #04C4D9; // since 2022-11-30
  $accent-tly: #ff7119;
  $accent-zly: #7f4ed4;
  $theme-v3e-blue-1: #4E6489;
  $theme-v3e-blue-2: #90A9D6;
  // variables from App.vue
  --columns-background: #{$theme-v3e-columns-background};
  --text-color-1: #{$theme-v3e-blue-1};
  --text-color-2: #{$theme-v3e-blue-2};

  background: var(--columns-background);

  &.app-imp {
    --accent-color: #{$accent-imp};
  }

  &.app-jgo {
    --accent-color: #{$accent-jgo};
  }

  &.app-jgo:not(.theme-v3e) {
    --accent-color: #{$accent-jgo-old};
    --accent-color_rgb: #{$accent-jgo_rgb-old};
    --text-color-1: rgba(0, 0, 0, .87); // override v3 with v2 color
    --text-color-2: rgba(0, 0, 0, .60); // override v3 with v2 color
    --text-color-3: rgba(0, 0, 0, .38); // override v3 with v2 color
    --columns-background: #{$theme-v2-columns-background}; // override v3 with v2 color
    --placeholder-color: rgba(255, 255, 255, .38);
    //
    background: #F4F1BE; /* change it also on App.vue */
  }

  &.app-tly {
    --accent-color: #{$accent-tly};
  }

  &.app-zly {
    --accent-color: #{$accent-zly};
  }
}

.indicator-container {
  height: 4px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 100000;
  background-color: var(--accent-color);

  .first-indicator,
  .second-indicator {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    transform-origin: left center;
    transform: scaleX(0);
    will-change: transform;
  }

  .first-indicator {
    animation: first-indicator 2000ms linear infinite;
    background-color: var(--text-color-1);
  }

  .second-indicator {
    animation: second-indicator 2000ms linear infinite;
    background-color: var(--text-color-2);
  }

  @keyframes first-indicator {
    0% {
      transform: translateX(0vw) scaleX(0);
    }
    25% {
      transform: translateX(0vw) scaleX(0.5);
    }
    50% {
      transform: translateX(25vw) scaleX(0.75);
    }
    75% {
      transform: translateX(100vw) scaleX(0);
    }
    100% {
      transform: translateX(100vw) scaleX(0);
    }
  }

  @keyframes second-indicator {
    0% {
      transform: translateX(0vw) scaleX(0);
    }
    60% {
      transform: translateX(0vw) scaleX(0);
    }
    80% {
      transform: translateX(0vw) scaleX(0.6);
    }
    100% {
      transform: translateX(100vw) scaleX(0.1);
    }
  }
}

.v3e-logo-container {
  height: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fadeIn ease 2s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  img {
    //width: auto;
    height: 100%;
  }

  .ball {
    position: absolute;
    top: 0;
    left: 0;
    //transform-origin: 52% center;
    animation: rotate 2s linear infinite;
    @keyframes rotate {
      0% {
        transform: rotate(-360deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  //.ball-reflection {
  //  position: absolute;
  //  top: 1.1rem;
  //  left: 0.4rem;
  //  width: 1.3rem;
  //  height: auto;
  //  transform-origin: 55% center;
  //  //animation: move-reflection 1s linear infinite;
  //  animation: move-reflection 1s alternate infinite ease-in-out;
  //  //border: 1px solid green;
  //  @keyframes move-reflection {
  //    100% {
  //      transform: rotate(-360deg) scale(0.7);
  //    }
  //  }
  //}
}
</style>
