import { useSocketStore } from '@/stores/socket';

import type { App as TypeApp } from 'vue';

import { createApp, markRaw } from 'vue';
import type { RouteLocationNormalizedLoaded, Router } from 'vue-router';
import * as Sentry from '@sentry/vue';

//
// Splash screen, before loading anything else (other stuff will take longer to load)
// although it loads after the store files are loaded
//
// import AppSplashScreen from '@/splashScreen';
//
// AppSplashScreen(); // to mount it
//
//
//
// configureCompat({
//   // COMPONENT_V_MODEL: false,
//   // RENDER_FUNCTION: false,
// });

//
// Validator
//
import { configure, defineRule } from 'vee-validate';
import { confirmed, email, max_value as maxValue, min, min_value as minValue, regex, required } from '@vee-validate/rules';

// import './registerServiceWorker'; // disabled for now
// Axios (instead of Vue-Resource)
import VueAxios from 'vue-axios';
import axios from 'axios';
import type { AxiosError, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';

// BootstrapVueNext
// https://bootstrap-vue-next.github.io/bootstrap-vue-next/docs.html
import { createBootstrap } from 'bootstrap-vue-next';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

// Tooltips
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

// Notifications
import Toasted from '@hoppscotch/vue-toasted';
import '@hoppscotch/vue-toasted/style.css';

// Vuescroll (because Firefox does not support styling of scrollbars;
// (bug report opened 16 years ago and not yet fixed)
// import vuescroll from 'vuescroll';
// import 'vuescroll/dist/vuescroll.css';
import VueScrollTo from 'vue-scrollto';
import type { ScrollOptions } from 'vue-scrollto';

// Video player
import VuePlyr from '@skjnldsv/vue-plyr';
// import './style/vue-plyr.css';
import '@skjnldsv/vue-plyr/dist/vue-plyr.css';

// Icons
import { config as faConfig, dom as faDom, library as faLibrary } from '@fortawesome/fontawesome-svg-core';

// Virtual scroller - Blazing fast scrolling of any amount of data (RecycleScroller component)
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

// AutoAnimate - Add motion to your apps with a single line of code (https://auto-animate.formkit.com/#usage-vue) (replaces unmaintained vue-smooth-reflow)
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';

import { useUtils } from '@/composables/useUtils';

//
// Relative imports
//
import PushNotification from '@/push-notification';

// Logger
import VueLogger from '@/logger';

//
// Components
//
import App from '@/App.vue';
import pinia from '@/stores';
// import { useGlobalStore } from '@/stores/global';
import { useAuthStore } from '@/stores/auth';
import { useAccountStore } from '@/stores/account';
// import { useSocketStore } from '@/stores/socket';
import { useTranslationStore } from '@/stores/translation';
import { useApiStore } from '@/stores/api';
import { useToastsStore } from '@/stores/toasts';
// import { useTreasureQuestStore } from '@/stores/treasureQuest';
// import { useDigsStore } from '@/stores/digs';
import i18nSetup from '@/i18n-setup';

//
// Font Awesome Icons
//

import {
  faAddressBook,
  faAddressCard,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsAlt,
  faAt,
  faBinoculars,
  faCalendar,
  faCaretDown,
  faCaretRight,
  faChartBar,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCircle,
  faComment,
  faComments,
  faCompress,
  faDiagramProject,
  faDrumstickBite,
  faEllipsisH,
  faEllipsisV,
  faExclamationCircle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileDownload,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faGear,
  faGlobe,
  faGlobeEurope,
  faHandPaper,
  faHandPointer,
  faHandshake,
  faHistory,
  faHome,
  faIdCard,
  faImage,
  faImagePortrait,
  faInfo,
  faInfoCircle,
  faKey,
  faLanguage,
  faLightbulb,
  faList,
  faMapSigns,
  faMinus,
  faPaperPlane,
  faPaperclip,
  faPencilAlt,
  faPlus,
  faProjectDiagram,
  faQuestion,
  faRedo,
  faRotateLeft,
  faRotateRight,
  faRuler,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortDown,
  faSortUp,
  faSpinner,
  faStickyNote,
  faStop,
  faSync,
  faTachometerAlt,
  faTasks,
  faTimes,
  faTrash,
  faUndo,
  faUser,
  faUserCog,
  faUsersCog,
} from '@fortawesome/free-solid-svg-icons';

import { faQuestionCircle as farQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { useGlobalStore } from '@/stores/global';

console.log('xxxxx Reached main.js');

if (window.STOP_LOADING) {
  throw new Error(`window has STOP_LOADING set to ${window.STOP_LOADING}`);
}

const app: TypeApp = createApp(App);
app.config.compilerOptions.isCustomElement = (tag) => ['fire', 'air', 'water', 'earth'].includes(tag);

const isNodeEnvProduction = process.env.NODE_ENV === 'production';

//
// Configure Axios
//
console.log('Axios baseURL being set by main.js!');
// Read API_URL and API_VERSION from generated env file (loaded at index.html)

if (window.ENV) {
  // axios.defaults.baseURL = `${window.ENV.API_URL || '//127.0.0.1'}/api/${window.ENV.API_VERSION || 'v1'}`;

  axios.defaults.baseURL = `/api/${window.ENV.API_VERSION || 'v1'}`;
} else {
  // No window.ENV
  // axios.defaults.baseURL = '//127.0.0.1';
  axios.defaults.baseURL = '/api/v1';
}

// Add a request interceptor to add role and lang params
axios.interceptors.request.use((_config) => {
  const accountStore = useAccountStore();
  const apiStore = useApiStore();
  const translationStore = useTranslationStore();

  // Do something before request is sent
  const config = { ..._config };

  // Exclude requests that don't require cancel or that define their own signal (old cancelToken)
  const hasSignal = !Object.prototype.hasOwnProperty.call(config, 'signal');
  if (hasSignal) {
    // app.config.globalProperties.$log.debug(`Request ${config.url} has custom signal, not adding to the cancel queue`);
  } else if (config.url) {
    const controller = new AbortController(); // 2023-07-31: CancelToken is deprecated since axios v0.22.0
    apiStore.addRequestToCancelQueue({
      url: config.url,
      controller,
    }); // join the cancel queue
    config.signal = controller.signal; // add to this request config
  }

  if (config.baseURL && config.baseURL.startsWith('/api')) {
    if (config.params === undefined) {
      config.params = {}; // the request did not have params set yet
    }
    //
    // lang
    //
    if (!('lang' in config.params)) {
      const { localeCode } = translationStore;
      if (localeCode) {
        config.params.lang = localeCode;
      }
    }
    //
    // role
    //
    const {
      userRole,
      userRoleIsNone,
      userRoleIsImpCoTeamLeader,
    } = accountStore;
    if (!('role' in config.params)) {
      if (!userRoleIsNone) {
        config.params.role = userRole.id;
      }
    }
    //
    // Co-Team Leader: select the Team Leader ID
    //
    if (userRoleIsImpCoTeamLeader) {
      const { selectedCoPilotPilot } = accountStore;
      if (selectedCoPilotPilot) {
        config.params.pilot = selectedCoPilotPilot.id;
      }
    }
  }
  return config;
}, (error) => {
  // Do something with request error
  console.log('Request error?', error);
  return Promise.reject(error);
});

const doNotRetryUrls = [
  '/session/log',
  '/auth/valid',
];

// Configure axios-retry
axiosRetry(axios, {
  retries: 3,
  // retryDelay: axiosRetry.exponentialDelay, // default is 2^retryNumber * 100 ms
  retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000),
  // retryCondition: axiosRetry.isRetryableError,
  retryCondition(error) {
    //   if (!error.response) {
    //     return false;
    //   }
    //   switch (error.response.status) {
    //     // retry only if status is 500 or 501
    //     case 500:
    //     case 501:
    //     case 404: // 404 for DEBUG ONLY
    //       return true;
    //     default:
    //       return false;
    //   }
    const isRetryableError = axiosRetry.isRetryableError(error);
    const isRetryableUrl = !!(error.config?.url && !doNotRetryUrls.includes(error.config.url));
    return isRetryableError && isRetryableUrl;
  },
  onRetry: (retryCount, _error, requestConfig) => {
    app.config.globalProperties.$log.error(`Retry #${retryCount} for ${requestConfig.method?.toUpperCase()} ${requestConfig.url}`);
    // if (retryCount === 2) {
    //   requestConfig.url = 'https://postman-echo.com/status/200';
    // }
  },
});

// Vue-Axios to enable Vue/this.$http
app.use(VueAxios, axios);
app.provide('$http', app.config.globalProperties.$http);

//
// Logger (after VueAxios: Vue.use will invoke the install method passing Vue as the first parameter)
//
app.use(VueLogger, {
  // optional : defaults to true if not specified
  isEnabled: true,
  // required ['debug', 'info', 'warn', 'error', 'fatal']
  logLevel: isNodeEnvProduction ? 'info' : 'debug',
  // optional : defaults to false if not specified
  stringifyArguments: false,
  // optional : defaults to false if not specified
  showLogLevel: true,
  // optional : defaults to false if not specified
  showMethodName: true,
  // optional : defaults to '|' if not specified
  separator: '|',
  // optional : defaults to false if not specified
  showConsoleColors: true,
});
app.provide('$log', app.config.globalProperties.$log);
useUtils()
  .injectApp(app);

// Notifications
app.use(Toasted, {
  position: 'bottom-right',
  duration: 1000 * 7, // 7 seconds
  keepOnHover: true,
  iconPack: 'fontawesome',
});
app.provide('$toasted', app.config.globalProperties.$toasted);

pinia.use(({ store }) => {
  store.$http = markRaw(app.config.globalProperties.$http);
  store.$log = markRaw(app.config.globalProperties.$log);
  store.$toasted = markRaw(app.config.globalProperties.$toasted);
});
app.use(pinia);

// const translationStore = useTranslationStore();
// translationStore.injectApp(app);
// const apiStore = useApiStore();
// apiStore.injectApp(app);
// const accountStore: ReturnType<typeof import('./stores/account')['useAccountStore']> = useAccountStore();
// accountStore.injectApp(app);

// const globalStore = useGlobalStore();
// const authStore = useAuthStore();
// const socketStore = useSocketStore();
// const toastsStore = useToastsStore();
// const treasureQuestStore = useTreasureQuestStore();
// const digsStore = useDigsStore();

// Inject app into the stores
// globalStore.injectApp(app);
// authStore.injectApp(app);
// socketStore.injectApp(app);
// toastsStore.injectApp(app);
// treasureQuestStore.injectApp(app);
// digsStore.injectApp(app);

//
// Validator
//
// Default values
configure({
  // validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  // validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
  // validateOnModelUpdate: true, // controls if `update:model-value` events should trigger validation with `handleChange` handler
});
defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('confirmed', confirmed);
defineRule('regex', regex);
defineRule('min-value', minValue);
defineRule('max-value', maxValue);
// defineRule('size', size);

// extend('secret', {
//   validate: (value) => value === 'example',
//   message: 'This is not the magic word',
// });

i18nSetup.initialize(app);
// app.provide('$t', app.config.globalProperties.$t);

// Tooltips
// app.use(VTooltip);
// VTooltip.options.defaultContainer = '#app'; // default is 'body'; setting it to #app to be able to use CSS variables
// VTooltip.options.popover.defaultContainer = '#app'; // default is 'body'; setting it to #app to be able to use CSS variables
app.use(FloatingVue); // default values: https://floating-vue.starpad.dev/guide/config.html#default-values
FloatingVue.options.container = '#app'; // default is 'body'; setting it to #app to be able to use CSS variables
/* app.use(FloatingVue, {
  placement: 'top',
  delay: 0,
  distance: 0,
  container: 'body',
  boundary: undefined,
  autoHide: true,
  disposeTimeout: 5000,
  themes: {
    tooltip: {
      html: true,
      triggers: ['hover', 'focus'],
      hideTriggers: (triggers) => [...triggers, 'click'],
      loadingContent: '...',
    },
    dropdown: {
      placement: 'bottom',
      delay: 0,
      triggers: ['click'],
      distance: 0,
      container: 'body',
      boundary: undefined,
      autoHide: true,
      handleResize: true,
    },
  },
}); */

//
app.use(VueScrollTo);
app.provide('$scrollTo', app.config.globalProperties.$scrollTo);
type ElementDescriptor = Element | string;
export interface ScrollToFunction {
  (options: ScrollOptions): () => void;
  (element: ElementDescriptor, options?: ScrollOptions): () => void;
  (element: ElementDescriptor, duration: number, options?: ScrollOptions): () => void;
}

// Vuescroll
// app.use(vuescroll, {
//   ops: {
//     bar: {
//       // background: '#00aaff',
//       background: '#78c1ed',
//     },
//   },
// });
// app.use(vuescroll, {
//   ops: {
//     // vuescroll
//     vuescroll: {
//       mode: 'native',
//       // vuescroll's size(height/width) should be a percent(100%)
//       // or be a number that is equal to its parentNode's width or
//       // height ?
//       sizeStrategy: 'percent',
//       /** Whether to detect dom resize or not */
//       detectResize: true,
//       // pullRefresh or pushLoad is only for the slide mode...
//       pullRefresh: {
//         enable: false,
//         tips: {
//           deactive: 'Pull to Refresh',
//           active: 'Release to Refresh',
//           start: 'Refreshing...',
//           beforeDeactive: 'Refresh Successfully!',
//         },
//       },
//       pushLoad: {
//         enable: false,
//         tips: {
//           deactive: 'Push to Load',
//           active: 'Release to Load',
//           start: 'Loading...',
//           beforeDeactive: 'Load Successfully!',
//         },
//       },
//       paging: false,
//       zooming: true,
//       snapping: {
//         enable: false,
//         width: 100,
//         height: 100,
//       },
//       /* shipped scroll options */
//       scroller: {
//         /** Enable bouncing (content can be slowly moved outside and jumps back after releasing) */
//         bouncing: true,
//         /** Enable locking to the main axis if user moves only slightly on one of them at start */
//         locking: true,
//         /** Minimum zoom level */
//         minZoom: 0.5,
//         /** Maximum zoom level */
//         maxZoom: 3,
//         /** Multiply or decrease scrolling speed */
//         speedMultiplier: 1,
//         /** This configures the amount of change applied to deceleration when reaching boundaries */
//         penetrationDeceleration: 0.03,
//         /** This configures the amount of change applied to acceleration when reaching boundaries */
//         penetrationAcceleration: 0.08,
//         /** Whether call e.preventDefault event when sliding the content or not */
//         preventDefault: true,
//       },
//     },
//     scrollPanel: {
//       // when component mounted.. it will automatically scrolls.
//       initialScrollY: false,
//       initialScrollX: false,
//       // feat: #11
//       scrollingX: true,
//       scrollingY: true,
//       speed: 300,
//       easing: undefined,
//       // Setting padding to true can give a padding-right to panel which size is equal
//       // to rail/bar's size.
//       padding: false,
//     },
//     //
//     rail: {
//       background: '#01a99a',
//       opacity: 0,
//       /** Rail's size(Height/Width) , default -> 6px */
//       size: '6px',
//     },
//     bar: {
//       /** How long to hide bar after mouseleave, default -> 500 */
//       showDelay: 0,
//       /** Whether to show bar on scrolling, default -> true */
//       onlyShowBarOnScroll: false,
//       /** Whether to keep show or not, default -> false */
//       keepShow: true,
//       /** Bar's background , default -> #00a650 */
//       background: '#c1c1c1',
//       /** Bar's opacity, default -> 1  */
//       opacity: 1,
//       /** Styles when you hover scrollbar, it will merge into the current style */
//       hoverStyle: false,
//     },
//   },
//   // name: 'myScroll', // customize component name, default -> vueScroll
// });

//
// Bootstrap
//
app.use(createBootstrap());

// Video player
app.use(VuePlyr);

// Virtual scroller
app.use(VueVirtualScroller);

// AutoAnimate - Add motion to your apps with a single line of code (https://auto-animate.formkit.com/#usage-vue)
app.use(autoAnimatePlugin);

faLibrary.add(
  faAddressCard,
  faAddressBook,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsAlt,
  faAt,
  faBinoculars,
  faExclamationCircle,
  faCalendar,
  faCaretRight,
  faCaretDown,
  faChartBar,
  faChartLine,
  faCheck,
  faCheckCircle,
  // faCheckSquare,
  faCircle,
  faComment,
  faComments,
  faCompress,
  faDiagramProject,
  faDrumstickBite,
  faEllipsisH,
  faEllipsisV,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  // Files
  faFile,
  faFileDownload,
  faFileImage,
  faFileAudio,
  faFileVideo,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileAlt,
  faFileCode,
  faFileArchive,
  // End of Files
  faGear,
  faGlobe,
  faGlobeEurope,
  faHandPaper,
  faHandPointer,
  faHandshake,
  faHistory,
  faHome,
  // faHundredPoints,
  faIdCard,
  faImage,
  faImagePortrait,
  faInfo,
  faInfoCircle,
  faKey,
  faLanguage,
  faLightbulb,
  faList,
  faMapSigns,
  faMinus,
  faPaperclip,
  faPaperPlane,
  faPencilAlt,
  faPlus,
  faProjectDiagram,
  faQuestion,
  farQuestionCircle, // regular
  faRedo,
  faRotateLeft,
  faRotateRight,
  faRuler,
  faSort,
  faSortAlphaUp,
  faSortAlphaDown,
  faSortDown,
  faSortUp,
  faStickyNote,
  faStop,
  faSync,
  faSpinner,
  faTachometerAlt,
  faTasks,
  faTimes,
  faTrash,
  faUndo,
  faUser,
  faUserCog,
  faUsersCog,
);
//
/**
 * Setting this config so that Vue-tables-2 will be able to replace sort icons with chevrons
 * https://fontawesome.com/how-to-use/with-the-api/setup/configuration
 */
faConfig.autoReplaceSvg = 'nest';

/**
 * Allows DOM to change <i> tags to SVG for more features like layering
 * https://fontawesome.com/how-to-use/on-the-web/styling/layering
 */
faDom.watch();
//
// end of Font Awesome Icons
//

// function removeElementById(id) {
//   (function removeElementByIdInnerFn(x) {
//     x.parentNode.removeChild(x);
//   }(document.getElementById(id)));
// }

function removePreloadAndMountVue(router: Router) {
  // All done. Hide the "loading" and start using Vue
  app.config.globalProperties.$log.debug('Done: will now hide the loading screen and will mount Vue');

  // removeElementById('app-preload');

  interface ErrorWithMessage {
    message: string;
  }

  function isErrWithMessage(err: unknown): err is ErrorWithMessage {
    return typeof err === 'object' && err !== null && 'message' in err && typeof err.message === 'string';
  }

  app.config.errorHandler = (err, vm, info) => {
    let route: RouteLocationNormalizedLoaded | undefined;

    if (vm && '$route' in vm && vm.$route) {
      route = vm.$route as RouteLocationNormalizedLoaded;
    }

    if (isErrWithMessage(err) && err.message.startsWith('Form has errors')) {
      // Ignore
      return;
    }
    if (isErrWithMessage(err) && err.message === 'Cannot read property \'offsetWidth\' of undefined') {
      // Cannot read property 'offsetWidth' of undefined
      app.config.globalProperties.$log.warn('Cannot read property \'offsetWidth\' of undefined (TO DO: check for an updated pdfvuer that would fix this error)');
      return;
    }

    const errStack = (typeof err === 'object' && err && 'stack' in err && err.stack) || '(no stack)';

    if (isErrWithMessage(err) && err.message === 'Cannot read properties of null (reading \'proxy\')') {
      // 2022-11-07 Ignore this error from vue-echarts (warn instead of error; everything seems to be working)
      app.config.globalProperties.$log.warn(`[Global Error Handler] at route ${route?.name?.toString()} with query ${JSON.stringify(route?.query)}. Details: ${info} / ${errStack}`);
      return;
    }
    // if (!vm) {
    //   app.config.globalProperties.$log.error(`[Global Error Handler] no vm. Details: ${info} / ${errStack}`);
    // } else if (vm.route) {
    if (route) {
      app.config.globalProperties.$log.error(`[Global Error Handler] at route ${route.name?.toString()} with query ${JSON.stringify(route.query)}. Details: ${info} / ${errStack}`);
    } else {
      app.config.globalProperties.$log.error(`[Global Error Handler] no route. Details: ${info} / ${errStack}`);
    }
  };

  const loadApp = true; // set to false if debugging the splash screen
  if (loadApp && !window.STOP_LOADING) {
    // new Vue({
    //   router,
    //   pinia,
    //   i18n,
    //   render: (h) => h(App),
    // }).$mount('#app');
    // app.use(router);
    router.isReady()
      .then(() => app.mount('#app-wrapper'));
  }
}

function logNetworkError(why: string, error: AxiosError & { config: { doNotLogErrorAgainIfThisRequestFails: boolean } }) {
  if (error && error.config && error.config.doNotLogErrorAgainIfThisRequestFails) {
    // Do not log it!
    console.error('Network error with config option "doNotLogErrorAgainIfThisRequestFails"; not sending to server.');
  } else {
    let finalMessage = `Network error (${why})`;
    finalMessage = `${finalMessage} | Method: ${error.config.method}`;
    finalMessage = `${finalMessage} | URL: ${error.config.url}`;
    finalMessage = `${finalMessage} | Status: ${(error.response && error.response.status) || '—'}`;
    finalMessage = `${finalMessage} | Data: ${error.response && error.response.data ? JSON.stringify(error.response.data) : '—'}`;
    finalMessage = `${finalMessage} | Message: ${error.message ? JSON.stringify(error.message) : '—'}`;

    finalMessage = `${finalMessage} | appVersion: ${(navigator && navigator.appVersion) || '—'}`;
    if (error.response && error.response.status && [301, 422].includes(error.response.status)) {
      // Warn instead of Error
      // 301: Moved Permanently
      // 422: Unprocessable Entity
      app.config.globalProperties.$log.warn(finalMessage);
    } else {
      app.config.globalProperties.$log.error(finalMessage);
    }
  }
}

// intercept the global error
axios.interceptors.response.use(
  (res: AxiosResponse) => {
    // Response OK
    if (res.config.signal && res.config.url) {
      const apiStore = useApiStore();
      apiStore.deleteRequestFromCancelQueue({
        url: res.config.url,
        abort: false,
      });
    }

    return res;
  },
  async (error) => {
    // app.config.globalProperties.$log.error('AXIOS INTERCEPTOR ERROR', error);
    const authStore = useAuthStore();
    const toastsStore = useToastsStore();

    // Response NOT OK
    if (error.config.url) {
      const apiStore = useApiStore();
      apiStore.deleteRequestFromCancelQueue(error.config.url);
    }

    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    if (!error.response) {
      // Network error
      // app.config.globalProperties.$toasted.show('(main.js) Network error', { type: 'error', icon: 'exclamation-circle' });
      app.config.globalProperties.$log.debug('Network error? Original request:', originalRequest);

      const ignoreFiles = /.*\.(?:svg|jpg|png|mp4)\?.*$/;
      if (ignoreFiles.test(originalRequest.url)) {
        // Ignore
        app.config.globalProperties.$log.warn(`Network error detected for file URL ${originalRequest.url}`);
        return Promise.reject(error);
      }
      // Log network error
      const jsonError = error.toJSON();
      if (jsonError && jsonError.config && jsonError.config.headers && jsonError.config.headers.Authorization) {
        jsonError.config.headers.Authorization = 'REDACTED';
      }
      logNetworkError(`is Axios error: ${error.isAxiosError}; Error JSON: ${JSON.stringify(jsonError)}`, error);
      // change to error page
      // router.push({ name: 'ErrorPage' });
      // Show toast instead of changing to error page
      await toastsStore.connectionLost();
      return Promise.reject(error);
    }

    if (originalRequest.method === 'post' && originalRequest.url.endsWith('/auth/login')) {
      // Sign in failed, do not intercept
      logNetworkError('sign in failed?', error);
      return Promise.reject(error);
    }
    if (originalRequest.method === 'post' && originalRequest.url.endsWith('/session/log')) {
      // Logging failed (user may be signed out), do not intercept
      // return Promise.reject(error);
    }

    const { status } = error.response;
    const UNAUTHORIZED = 401;
    if (status && status === UNAUTHORIZED) {
      if (authStore.isSigningOut) {
        const message = 'Got 401 (UNAUTHORIZED) and the user is being signed out. Not retrying.';
        app.config.globalProperties.$log.debug(message);
        return Promise.reject(new Error(message));
      }
      if (!authStore.isAuthenticated) {
        const message = 'Got 401 (UNAUTHORIZED) and the user is not authenticated. Not retrying.';
        app.config.globalProperties.$log.debug(message);
        return Promise.reject(new Error(message));
      }

      if (typeof originalRequest.__retryRequestCount === 'number') {
        // retryRequestCount already exists. Increment it (will be the 1st or 2nd retry)
        originalRequest.__retryRequestCount += 1;
        const message = `Got 401 (UNAUTHORIZED) (${originalRequest.url}). Retry #${originalRequest.__retryRequestCount}.`;
        app.config.globalProperties.$log.warn(message);
      } else {
        // the error is 401 and hasn’t already been retried
        originalRequest.__retryRequestCount = 0;
        // now it can be retried after getting an updated token
      }
      if (originalRequest.__retryRequestCount > 3) {
        // Log error and reject
        const message = `Got 401 (UNAUTHORIZED) (${originalRequest.url}) for the third time. Not retrying.`;
        app.config.globalProperties.$log.error(message);
        return Promise.reject(new Error(message));
      }

      const gotInvalidTokenError = error.response.data && typeof error.response.data === 'object' && error.response.data.message === 'invalid_token';
      if (gotInvalidTokenError) {
        app.config.globalProperties.$log.debug('Got invalid token error. Will try to get an updated token.');

        if (originalRequest.method === 'post' && originalRequest.url.endsWith('/session/log')) {
          // Add info to the message, so that when replaying with a valid token we know that this message led to a 401
          const data = JSON.parse(originalRequest.data);
          if ('message' in data) {
            data.message = `${data.message} | (log POST repeated with new token after 401)`;
            originalRequest.data = JSON.stringify(data);
          }
        }

        try {
          const newTokens = await authStore.refreshTokenAfter401Unauthorized(originalRequest.url);
          // Got the new token; retry the request that errored out
          app.config.globalProperties.$log.debug(`Got new tokens; retrying the request ${originalRequest.url}`);
          originalRequest.headers.Authorization = `Bearer ${newTokens?.accessToken}`;
          return app.config.globalProperties.$http(originalRequest);
        } catch {
          // Could not get a new token
          // app.config.globalProperties.$log.debug('Could not get a new token; going to Sign In page.');
          // router.push({ name: 'SignIn' }); // this is already done at the auth store
          return await Promise.reject(error);
        } finally {
          //
        }
      } else {
        // Not "invalid_token" error message, log it now after getting this new token
        logNetworkError('unauthorized and not an invalid token message?', error);
        app.config.globalProperties.$log.debug('Unauthorized (but error is not invalid token)! Will NOT try to get an updated token.');
        return Promise.reject(error);
      }
    }

    const FORBIDDEN = 403;
    if (error.response
      && error.response.status === FORBIDDEN
      && originalRequest.url.match(/\/api\/v1\/session\/entities\/\d+\/block/)
    ) {
      // Could not load user block. Do not report as an error.
      app.config.globalProperties.$log.warn('Entity block forbidden');
      return Promise.reject(error);
    }

    const NOT_FOUND = 404;
    if (status === NOT_FOUND
      && error.response
      && error.response.headers) {
      if (error.response.headers['x-request-id']) {
        // Resource does not exist (it's not a not found route because headers has x-request-id)
        // No need to log as error
        app.config.globalProperties.$log.info('API resource does not exist?', error.response.data);
        return Promise.reject(error);
      }
      // Not found route?
      app.config.globalProperties.$log.error('API route does not exist?', error.response.data);
      logNetworkError('API route does not exist?', error);
      return Promise.reject(error);
    }

    const BAD_GATEWAY = 502;
    if (status === BAD_GATEWAY) {
      await toastsStore.connectionLostBadGateway();
      app.config.globalProperties.$log.warn('Connection lost?');
      // Do not log to backend
      return Promise.reject(error);
    }

    logNetworkError(`status ${status}`, error);
    return Promise.reject(error);
  },
);

(async () => {
  let routerFileName = 'router-imp'; // default
  const appName = import.meta.env.VITE_APP_NAME;
  if (appName) {
    // TODO: tree shaking not working
    switch (appName.toLowerCase()) {
      case 'jgo':
        routerFileName = 'router-jgo';
        break;
      case 'tly':
        routerFileName = 'router-tly';
        break;
      case 'zly':
        routerFileName = 'router-zly';
        break;
      // no default
    }
  }
  const importObj = await import(`./router/${routerFileName}.ts`); // .ts extension needed here, or else "dynamic import cannot be analyzed by Vite."
  const Router = importObj.default;
  //
  //
  //

  if (typeof Router !== 'function') {
    // 2023-10-31: started happening in Vitest: "TypeError: Router is not a function", originated in "tests/unit/specs/account/SignIn.spec.js" and "tests/unit/specs/router.spec.js"
    console.log('Router import failed?');
    return;
  }
  const router: Router = await Router(app);

  //
  // https://docs.sentry.io/platforms/javascript/guides/vue/
  //
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN || '',
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        Sentry.vueIntegration({
          tracingOptions: {
            trackComponents: true,
            hooks: [
              'mount',
              // 'update', // add this line to re-enable update spans
              'unmount',
            ],
          },
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      tracePropagationTargets: ['localhost', /^https:\/\/my.*\.com\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      maxValueLength: 1000, // default is 250
    });
  } else {
    console.warn('VITE_SENTRY_DSN not set; skipping Sentry.init()');
  }

  const translationStore = useTranslationStore();
  await translationStore.initialize(); // fetch the available languages and current user language (session/profile/browser)

  PushNotification.injectApp(app); // before the auth store
  const globalStore = useGlobalStore();
  PushNotification.setGlobalStore(globalStore);

  app.config.globalProperties.$log.debug('main.js: will initialize Auth store.');
  // Init auth store and wait for it, because it will set the access token and will fetch the account, so we know what the user can access
  const authStore = useAuthStore();
  await authStore.initialize();
  app.config.globalProperties.$log.debug('main.js: Auth store initialized.');

  // Inject router into the stores
  const accountStore = useAccountStore();
  const socketStore = useSocketStore();
  const toastsStore = useToastsStore();
  accountStore.injectRouter(router);
  authStore.injectRouter(router);
  globalStore.injectRouter(router);
  socketStore.injectRouter(router);
  toastsStore.injectRouter(router);

  app.use(router); // after initializing the auth store, to know the first route

  // Done
  removePreloadAndMountVue(router);
})();
